import Utils from "platform/util/Utils";

enum LangCode {
    NONE = "",
    EN = "en",
    ES = "es",
    FR = "fr",
    IT = "it",
    EL = "el",
    DE = "de",
    PL = "pl",
    PT = "pt",
    RU = "ru",
    ZH = "zh",
    SK = "sk",
    CS = "cs",
    JA = "ja",
    FI = "fi",
    SW = "sw",
    SV = "sv",
    TH = "th",
    TR = "tr",
    // Rtl Codes
    AR = "ar",
    ARC = "arc",
    DV = "dv",
    FAR = "far",
    HA = "ha",
    HE = "he",
    KHW = "khw",
    KS = "ks",
    PS = "ps",
    UR = "ur",
    YI = "yi",
}

namespace LangCode {

    export function rtlCode(langCode: LangCode | string): boolean {
        if (Utils.isNotNull(langCode)) {
            switch (langCode) {
                case LangCode.AR:
                case LangCode.ARC:
                case LangCode.DV:
                case LangCode.FAR:
                case LangCode.HA:
                case LangCode.HE:
                case LangCode.KHW:
                case LangCode.KS:
                case LangCode.PS:
                case LangCode.UR:
                case LangCode.YI:
                    return true;
            }
        }
        return false;
    }

    export function countryCode(langCode: LangCode | string): string {
        if (Utils.isNotNull(langCode)) {
            switch (langCode) {
                case LangCode.EN:
                    return "GB";
                case LangCode.EL:
                    return "GR";
                default:
                    return langCode.toUpperCase();
            }
        }
        return null;
    }
}

export {LangCode};
