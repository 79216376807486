import {ActionCreatorDefinition, createAction} from "redux-ts";
import Parameter from "platform/util/Parameter";

export const ExecuteDpkType: string =  "ExecuteDpk";

export interface ExecuteDpkPayload {

    dpk: string;
    params?: Parameter[];
    action?: (routeType: string, params?: Parameter[]) => void;
}

export const ExecuteDpk: ActionCreatorDefinition<ExecuteDpkPayload, any> = createAction<ExecuteDpkPayload>(ExecuteDpkType);
