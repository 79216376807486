import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {AppReduxState} from "core/redux/app/AppReduxState";
import {
    CloseMeType,
    DoGetURLType,
    DoRegisterActivityType,
    SetBackNavigation,
    SetCallMe,
    SetCanManageLimits,
    SetCountries,
    SetCountriesPayload,
    SetDynamicConfiguration,
    SetDynamicConfigurationPayload,
    SetHideBackToPlatformButton,
    SetKycContext,
    SetKycThirdPartyRedirect,
    SetKycUrl,
    SetModal,
    SetModalPayload,
    SetTooltip,
    SetTooltipPayload,
    SetWebViewPlatform,
    SetWebViewPlatformPayload,
    SetUIError,
} from "core/redux/app/AppReduxActions";
import AppEngine from "core/engine/AppEngine";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {LoadLanguageType} from "platform/redux/translation/TranslationActions";
import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {InitChat, SetAppReadyType, SetBrandPropsType} from "platform/redux/core/CoreActions";
import {BooleanPayload, StringPayload} from "core/redux/StoreActions";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {ModalType} from "enum/ModalType";

export default class AppReducer extends Reducer<AppReduxState> {

    private static _instance: AppReducer;

    public static instance(): AppReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const appEngine: AppEngine = Platform.engine(ServiceType.App);
        this._middlewareActions.set(SetBrandPropsType, appEngine.doSetBrandProps);
        this._middlewareActions.set(SetAppReadyType, appEngine.onAppReady);
        this._middlewareActions.set(LoadLanguageType, appEngine.onLoadLanguage);
        this._middlewareActions.set(CloseMeType, appEngine.closeMe);
        this._middlewareActions.set(DoRegisterActivityType, appEngine.doRegisterActivity);
        this._middlewareActions.set(DoGetURLType, appEngine.doGetUrl);
    }

    public get name(): string {
        return "app";
    }

    protected setup(builder: ReducerBuilder<AppReduxState>): void {
        builder
            .init({
                dynamicConfiguration: {},
                countries: new TSMap<string, CountryInfo>(),
                modals: new TSMap<ModalType, boolean>()
            })
            .handle(SetDynamicConfiguration, (state: AppReduxState, {payload}: Action<SetDynamicConfigurationPayload>) => {
                return Object.assign({}, state, {
                    dynamicConfiguration: payload.configuration
                });
            })
            .handle(SetWebViewPlatform, (state: AppReduxState, {payload}: Action<SetWebViewPlatformPayload>) => {
                this._logger.debug(`Set webview platform to: ${payload.webViewPlatform}`);
                return Object.assign({}, state, {
                    webViewPlatform: payload.webViewPlatform
                });
            })
            .handle(SetCountries, (state: AppReduxState, {payload}: Action<SetCountriesPayload>) => {
                return Object.assign({}, state, {
                    countries: payload.countries
                });
            })
            .handle(SetModal, (state: AppReduxState, {payload}: Action<SetModalPayload>) => {
                const newState: AppReduxState = Utils.merge({}, state);
                newState.modals.set(payload.modalType, payload.visible);
                return newState;
            })
            .handle(InitChat, (state: AppReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    zeChatAvailable: true
                });
            })
            .handle(SetTooltip, (state: AppReduxState, {payload}: Action<SetTooltipPayload>) => {
                return Object.assign({}, state, {
                    tooltip: payload.tooltip
                });
            })
            .handle(SetCallMe, (state: AppReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    callMeAvailable: payload.value
                });
            })
            .handle(SetKycUrl, (state: AppReduxState, {payload}: Action<StringPayload>) => {
                return Object.assign({}, state, {
                    kycUrl: payload.value
                });
            })
            .handle(SetKycContext, (state: AppReduxState, {payload}: Action<BooleanPayload>) => {
                this._logger.debug(`Set KYC context: ${payload.value}`);
                return Object.assign({}, state, {
                    kycContext: payload.value
                });
            })
            .handle(SetKycThirdPartyRedirect, (state: AppReduxState, {payload}: Action<BooleanPayload>) => {
                this._logger.debug(`Set KYC ThirdPartyRedirect: ${payload.value}`);
                return Object.assign({}, state, {
                    kycThirdPartyRedirect: payload.value
                });
            })
            .handle(SetBackNavigation, (state: AppReduxState, {payload}: Action<BooleanPayload>) => {
                this._logger.debug(`Set Back navigation: ${payload.value}`);
                return Object.assign({}, state, {
                    hasBackNavigation: payload.value
                });
            })
            .handle(SetHideBackToPlatformButton, (state: AppReduxState, {}: Action<any>) => {
                return Object.assign({}, state, {
                    hideBackToPlatformButton: true
                });
            })
            .handle(SetCanManageLimits, (state: AppReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    canManageLimits: payload.value
                });
            })
            .handle(SetUIError, (state: AppReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    UIError: payload.value
                });
            });
    }
}
