import {Action, ReducerBuilder} from "redux-ts";
import {TSMap} from "typescript-map";
import {Reducer} from "platform/redux/Reducer";
import {NetworkStatus} from "platform/enum/NetworkStatus";
import {NetworkType} from "platform/network/Network";
import {ConnectionReduxState} from "platform/redux/connection/ConnectionReduxState";
import {SetNetworkStatus, SetNetworkStatusPayload} from "platform/redux/connection/ConnectionActions";
import Utils from "platform/util/Utils";

export default class ConnectionReducer extends Reducer<ConnectionReduxState> {

    private static _instance: ConnectionReducer;

    private constructor() {
        super();
    }

    public static instance(): ConnectionReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "connection";
    }

    protected setup(builder: ReducerBuilder<ConnectionReduxState>): void {
        builder
            .init({
                statuses: new TSMap<NetworkType, NetworkStatus>()
            })
            .handle(SetNetworkStatus, (state: ConnectionReduxState, {payload}: Action<SetNetworkStatusPayload>) => {
                this._logger.debug("Set network status: " + NetworkStatus[payload.status] + " For: " + NetworkType[payload.networkType]);
                const newState: ConnectionReduxState = Utils.merge({}, state);
                newState.statuses.set(payload.networkType, payload.status);
                return newState;
            });
    }
}
