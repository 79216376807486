import {TranslationLoader} from "platform/translation/TranslationLoader";
import {LangCode} from "platform/enum/LangCode";
import {Http} from "platform/network/http/Http";

export class TranslationLoaderImpl implements TranslationLoader {

    private static _instance: TranslationLoader;

    private constructor() {
    }

    public static instance(): TranslationLoader {
        return this._instance || (this._instance = new this());
    }

    public load(langCode: LangCode): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                Http.getJson("resources/translation/" + langCode + ".json").then((data) => {
                    resolve(data);
                }).catch((e) => {
                    reject(e);
                });
            }
        );
    }
}
