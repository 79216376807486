import React from "react";
import {connect} from "react-redux";
import {StoreState} from "core/redux/StoreState";
import {LangCode} from "platform/enum/LangCode";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import Utils from "platform/util/Utils";
import {Popup} from "platform/redux/popups/PopupsReduxState";
import {Dispatch} from "redux";
import {HidePopup} from "platform/redux/popups/PopupsActions";
import {SetModal} from "core/redux/app/AppReduxActions";
import {ModalType} from "enum/ModalType";
import {HtmlToReact} from "component/util/HtmlToReact";

interface IProps {

    langCode?: LangCode;
    clazz?: string;
    popUpId?: number;
    popup?: Popup;
    dispatch?: Dispatch<any>;
}

const Limit: React.FC<IProps> = ({clazz, popup, dispatch}: IProps) => {

    const onHandleClick = (e): void => {
        e.stopPropagation();
        e.preventDefault();
        if (popup) {
            dispatch(HidePopup({popup}));
        }
        dispatch(SetModal({
            modalType: ModalType.DepositLimits,
            visible: true
        }));
    }

    return <div id={"deposit-limit-link"} className={`deposit-limit-link ${clazz}`}>
        {HtmlToReact(Translations.text(TranslationKey.errorDepositLimits), {
            replace(domNode: any) {
                if (domNode.name === "span") {
                    return <span key={Utils.uuid()}
                                 onMouseDown={onHandleClick}
                                 onTouchStart={onHandleClick}
                                 onTouchEnd={onHandleClick}>
                                    {domNode.children[0].data}
                                </span>
                }
                return domNode;
            }
        })}
    </div>;
};

const DepositLimit: React.FC<IProps> = connect((state: StoreState, ownProps: IProps): Partial<IProps> => {
    let popup: Popup;
    if (ownProps.popUpId) {
        popup = state.popups.popups.filter((popup => popup.remoteId === ownProps.popUpId))[0];
    }
    return {
        langCode: state.translation.langCode,
        popup
    };
}, (dispatch: Dispatch<any>, ownProps: any) => {
    return Object.assign({}, ownProps, {
        dispatch
    });
})(Limit) as any;

export {
    DepositLimit
};
