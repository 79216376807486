import {ConnectionProvider} from "platform/integration/AbstractIntegration";
import {ConnectionListener} from "platform/listener/ConnectionListener";
import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationConnection} from "platform/integration/connection/IntegrationConnection";
import PostMessageConnection from "platform/integration/connection/PostMessageConnection";
import WebUtil from "platform/util/WebUtil";
import {WebWindow} from "platform/integration/win/WebWindow";

export class PostMessageConnectionProvider implements ConnectionProvider {

    private static _instance: PostMessageConnectionProvider;

    private constructor() {
    }

    public static instance(): PostMessageConnectionProvider {
        return this._instance || (this._instance = new this());
    }

    public createConnection(win: WebWindow, listener: ConnectionListener<IntegrationMessage>): IntegrationConnection<IntegrationMessage> {
        return new PostMessageConnection(WebUtil.window(), win, listener);
    }
}
