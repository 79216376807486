import Utils from "platform/util/Utils";

export class RNBridge {

    private constructor() {
    }

    public static hasBridge = (): boolean => {
        return Utils.isNotNull(window.ReactNativeWebView);
    }

    public static send = (msg: string): void => {
        if (this.hasBridge()) {
            window.ReactNativeWebView.postMessage(msg);
        }
    }
}
