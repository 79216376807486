import {TSMap} from "typescript-map";
import {AvailableLanguage} from "protocol/response/LoginConfigurationResponse";
import {LangCode} from "platform/enum/LangCode";

export class AppState {

    private static _instance: AppState;
    private _languages: TSMap<LangCode, AvailableLanguage> = new TSMap();
    private _APMToken: object;
    private _APMCompletion: (success: boolean) => void;

    private constructor() {
    }

    public static instance(): AppState {
        return this._instance || (this._instance = new this());
    }

    public addLanguage(langCode: LangCode, language: AvailableLanguage): void {
        this._languages.set(langCode, language);
    }

    public getLanguage(langCode: LangCode): AvailableLanguage {
        return this._languages.get(langCode);
    }

    public get APMToken(): object {
        return this._APMToken;
    }

    public set APMToken(value: object) {
        this._APMToken = value;
    }

    public get APMCompletion(): (success: boolean) => void {
        return this._APMCompletion;
    }

    public set APMCompletion(value: (success: boolean) => void) {
        this._APMCompletion = value;
    }
}
