import {TSMap} from "typescript-map";
import {Logger} from "platform/logger/Logger";
import GlobalIntegration from "platform/integration/GlobalIntegration";
import {IMessageHandler} from "platform/handler/MessageHandler";
import {IntegrationPushInMemoryValue} from "platform/integration/message/IntegrationPushInMemoryValue";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";
import {RNBridge} from "core/integration/RNBridge";
import {IntegrationGetInMemoryValue} from "platform/integration/message/IntegrationGetInMemoryValue";
import {IntegrationSetInMemoryValue} from "platform/integration/message/IntegrationSetInMemoryValue";

export class InMemoryKeyValue {

    private static logger: Logger = Logger.Of("InMemoryKeyValue");
    private static promises: TSMap<string, (v: string) => void> = new TSMap<string, (v: string) => void>();
    private static initialized: boolean;

    private static InitIfNeed = (): void => {
        if (!this.initialized) {
            this.initialized = true;
            GlobalIntegration.instance().setHandler(new (class PushInMemoryValueHandler implements IMessageHandler<IntegrationPushInMemoryValue> {
                classId(): number {
                    return IntegrationClassId.PUSH_IN_MEMORY_VALUE;
                }

                handle(message: IntegrationPushInMemoryValue): void {
                    InMemoryKeyValue.logger.debug(`Handle in memory value by key: ${message.key}. Value: ${message.value}`);
                    InMemoryKeyValue.Resolve(message.key, message.value);
                }
            })());
        }
    }

    private static Resolve = (key: string, value: string): void => {
        const resolve: (v: string) => void = this.promises.get(key);
        this.promises.delete(key);
        if (resolve) {
            resolve(value);
        }
    }

    public static Get = (key: string): Promise<string> => {
        this.logger.debug(`Try to Fetch in memory value by key: ${key}`);
        this.InitIfNeed();
        return new Promise<any>(
            (resolve, reject) => {
                this.promises.set(key, resolve);
                RNBridge.send(JSON.stringify(new IntegrationGetInMemoryValue(key)));
            }
        );
    }

    public static Set = (key: string, value: string): void => {
        this.logger.debug(`Try to Set in memory value by key: ${key}. Value: ${value}`);
        RNBridge.send(JSON.stringify(new IntegrationSetInMemoryValue(key, value)));
    }
}
