import Utils from "platform/util/Utils";

enum ThemeType {

    Light = "light",
    Dark = "dark",
}

namespace ThemeType {

    export function deserialize(sTheme: string): ThemeType {
        if (Utils.isNotEmpty(sTheme)) {
            switch (sTheme) {
                case ThemeType.Dark:
                    return ThemeType.Dark;
            }
        }
        return ThemeType.Light;
    }
}

export {
    ThemeType
};
