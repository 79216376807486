import {ConnectionListener} from "platform/listener/ConnectionListener";

export default class ConnectionListenerAdapter<MessageType> implements ConnectionListener<MessageType> {

      public onConnected(): void {}

      public onDisconnected(): void {}

      public onMessage(message: MessageType, origin?: string): void {}

      public onError(): void {}

      public onConnecting(): void {}
}
