export interface DepositVerifyAPMResponse {

    EWalletTransaction: any;
    RequestId: number;
    StatusType: EWalletReceiptStatus;
    RequestAmountDetails: EWalletRequestDetails;
    EWalletAccountEmail: string;
    EWalletName: string;
    PaymentAssetName: string;
    LabelContactPhoneNumber: string;
    LabelEmail: string;
    TradingPlatformEmail: string;
    FullName: string;
    Url: string;
    Errors: VerifyAPMError[];
    HasErrors: boolean;
}

export enum EWalletReceiptStatus {

    Initial = 1,
    Pending = 2,
    Success = 3,
    Failed = 4,
    Cancelled = 5
}

export interface EWalletRequestDetails {

    Amount: number;
    CommissionRate: number;
    EURAmount: number;
    IsSystemBaseAssetAmountExternal: boolean;
    PaymentAmount: number;
    PaymentAssetId: number;
    PaymentConversionRate: number;
    SelectedAmount: number;
    SelectedAssetId: number;
    SystemBaseAssetAmount: number;
    SystemBaseAssetId: number;
}

export interface EWalletTransaction {

    AccountId: number;
    Amount: number;
    ApplicationType: number;
    BINCountryId: number;
    BeneficiaryId: number;
    BonusPromotionCode: string;
    CancelationType: number;
    CancelsTransaction: any;
    CardNumber: string;
    Comments: string;
    CounterPartyLabelId: number;
    CreatedByAccountId: number;
    DateTime: number;
    EURAmount: number;
    EWalletAccountId: number;
    EWalletDepositRequestId: number;
    EWalletPaymentMethodName: string;
    EWalletPaymentName: string;
    ExpirationDate: number;
    ExternalTransactionId: number;
    FraudFlagOtherRemark: string;
    FraudFlagReferenceId: number;
    FraudFlagTypeId: number;
    FraudFlagTypeName: string;
    Id: number;
    InitiatorType: number;
    IsCanceled: boolean;
    IsFirstDeposit: boolean;
    LabelId: number;
    PSP: string;
    PSPAccount: string;
    PaymentAmount: number;
    PaymentAssetId: number;
    PaymentMethod: number;
    PlatformType: number;
    ProofDocumentId: number;
    ProxyTransactionId: number;
    ReceiptNumber: string;
    SentToEWalletAmount: number;
    SentToEWalletAssetId: number;
    SystemBaseAssetAmount: number;
    SystemBaseAssetId: number;
    TransactionReferenceNumber: string;
    TransactionType: number;
}

export interface VerifyAPMError {

    ErrorCode: number;
    ErrorDescription: string;
}
