import * as EnTranslations from "../../resources/translations/en.json";

import * as Bowser from "bowser";
import React from "react";
import ReactDOM from "react-dom/client";
import {DepositService} from "core/service/DepositService";
import {Logger} from "platform/logger/Logger";
import {Environment} from "platform/enum/Environment";
import {AppService} from "core/service/AppService";
import {Service} from "platform/service/Service";
import Platform, {SetupResult} from "platform/Platform";
import ConfigManager from "core/configuration/ConfigManager";
import {TranslationLoaderImpl} from "core/translation/TranslationLoaderImpl";
import WebEnvironment from "platform/environment/WebEnvironment";
import {Router5} from "platform/router/router5/Router5";
import {Routes} from "core/router/Routes";
import WebUtil from "platform/util/WebUtil";
import {PageType} from "enum/PageType";
import {BrandType} from "platform/enum/BrandType";
import {StoreState} from "core/redux/StoreState";
import Script from "platform/network/script/Script";
import {createTheme} from "@mui/material/styles";
import {MuiFonts} from "platform/font/MuiFonts";
import {SetAppReady} from "platform/redux/core/CoreActions";
import {Provider} from "react-redux";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {RouterProvider} from "react-router5";
import {DepositAsync, DepositMobileAsync} from "entry/async";
import Translations from "platform/translation/Translations";
import {LangCode} from "platform/enum/LangCode";
import {XhrHeaders} from "platform/network/xhr/XhrHeaders";

const logger: Logger = Logger.Of("Boot");
const BowserDetails: Bowser.Parser.Details = WebUtil.BowserDetails();
XhrHeaders.browserName = BowserDetails?.name;
XhrHeaders.browserVersion = BowserDetails?.version;

if (Environment.isProd()) {
    const loadSentry = async () => {
        return await import('@sentry/react');
    };
    const loadTracing = async () => {
        return await import('@sentry/tracing');
    };
    loadSentry().then((Sentry) => {
        loadTracing().then(({BrowserTracing}) => {
            try {
                Sentry.init({
                    dsn: "https://d9295aa15cd84ba99c8bab7e2b0f3393@o1178767.ingest.sentry.io/6442817",
                    integrations: [new BrowserTracing()],
                    tracesSampleRate: 1.0,
                } as any);
            } catch (e) {
            }
        });
    });
}

Translations.preset(LangCode.EN, EnTranslations);
const Content = (): JSX.Element => {
    return WebUtil.isMobile() ? <DepositMobileAsync /> : <DepositAsync />;
}

const services: Array<{service: Service<any, any>, props?: any}> = [
    {service: AppService.instance()},
    {service: DepositService.instance()},
];

Platform.setup({
    configurationLoader: ConfigManager.loader(),
    translationLoader: TranslationLoaderImpl.instance(),
    environment: WebEnvironment.instance(),
    router: Router5.instance({
        routes: Routes.routes(),
        defaultRoute: PageType.DepositTopUp,
        defaultParams: WebUtil.getParamsToObject()
    }),
    services
}).then((setupResult: SetupResult) => {
    const isMobile: boolean = WebUtil.isMobile();
    const brand: BrandType = Platform.reduxState<StoreState>().core.brand;
    logger.info("Platform setup finished. Brand: " + brand);
    Script.injectLink("/css/" + brand + (isMobile ? "-mobile" : "") + ".css");

    const muiTheme = createTheme({
        typography: {
            fontFamily: MuiFonts.get(brand).join(","),
        }
    });

    Platform.dispatch(SetAppReady({ready: true}));
    const root = ReactDOM.createRoot(document.getElementById("RootElement"));
    root.render(
        <Provider store={Platform.store()}>
            <MuiThemeProvider theme={muiTheme}>
                <RouterProvider router={Platform.router().router()}>
                    <>
                        {Content()}
                    </>
                </RouterProvider>
            </MuiThemeProvider>
        </Provider>
    );
}).catch((e) => {
    logger.info("Failed setup platform: " + e);
});
