import {ActionCreatorDefinition, createAction} from "redux-ts";

export const PlaySoundType: string =  "PlaySound";

export interface PlaySoundPayload {

    name: string;
}

export const PlaySound: ActionCreatorDefinition<PlaySoundPayload, any> = createAction<PlaySoundPayload>(PlaySoundType);
