import {RemoteWin} from "platform/integration/win/RemoteWin";
import {IntegrationWinClose} from "platform/integration/message/IntegrationWinClose";
import {WinInitMeta} from "platform/integration/message/handshake/IntegrationWinInit";
import {IntegrationNavigateBack} from "platform/integration/message/IntegrationNavigateBack";
import {FrameUid} from "platform/enum/FrameUid";
import {IntegrationWillDoLater} from "platform/integration/message/IntegrationWillDoLater";
import {IntegrationShowLoading} from "platform/integration/message/IntegrationShowLoading";
import {IntegrationHideLoading} from "platform/integration/message/IntegrationHideLoading";
import {Logger} from "platform/logger/Logger";
import {UrlKycContext} from "core/util/UrlConstants";
import {IntegrationBridgeType} from "platform/integration/bridge/IntegrationBridgeType";
import {IntegrationSetInMemoryValue} from "platform/integration/message/IntegrationSetInMemoryValue";
import {IntegrationOpenExternalUrl} from "platform/integration/message/IntegrationOpenExternalUrl";

class Bridge {

    private static _instance: Bridge;
    private readonly _logger: Logger = Logger.Of("Bridge");
    private readonly _remoteWin: RemoteWin;

    private constructor() {
        this._logger.debug("Create remote win");
        this._remoteWin = RemoteWin.instance((meta: WinInitMeta, done: () => void): void => {
            done();
            this._logger.debug("Remote win initialized");
        }, FrameUid.Deposit);
        this._logger.debug("Try to connect");
        this._remoteWin.connect(UrlKycContext ? IntegrationBridgeType.HTML5 : null);
    }

    public static Instance = (): Bridge => {
        return this._instance || (this._instance = new this());
    }

    public close = (): void => {
        this._logger.debug("Call to parent 'close me'");
        this._remoteWin.sendMessage(new IntegrationWinClose());
    }

    public back = (): void => {
        this._logger.debug("Call to parent 'back'");
        this._remoteWin.sendMessage(new IntegrationNavigateBack());
    }

    public willDoLater = (): void => {
        this._logger.debug("Call to parent 'will do later'");
        this._remoteWin.sendMessage(new IntegrationWillDoLater());
    }

    public showLoader = (): void => {
        this._logger.debug("Call to parent 'show loader'");
        this._remoteWin.sendMessage(new IntegrationShowLoading());
    }

    public hideLoader = (): void => {
        this._logger.debug("Call to parent 'hide loader'");
        this._remoteWin.sendMessage(new IntegrationHideLoading());
    }

    public setInMemory = (key: string, value: string): void => {
        this._logger.debug(`Set in memory key: ${key}. Value: ${value}`);
        this._remoteWin.sendMessage(new IntegrationSetInMemoryValue(key, value));
    }

    public openUrl = (url: string): void => {
        this._logger.debug(`Ask to open URL: ${url}`);
        this._remoteWin.sendMessage(new IntegrationOpenExternalUrl(url));
    }
}

export {
    Bridge
};
