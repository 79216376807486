import Utils from "platform/util/Utils";
import {FormMethod} from "platform/enum/FormMethod";

export default class SubmitForm {

    private constructor() {}

    public static submit(url: string, method: FormMethod, parameters?: { [key: string]: string }): void {
        const formId: string = "form-" + Utils.randomInt(0, 100);
        const body: HTMLBodyElement = document.getElementsByTagName("body")[0];
        let form: HTMLFormElement = document.getElementById(formId) as HTMLFormElement;
        if (Utils.isNotNull(form)) {
            body.removeChild(form);
        }
        form = document.createElement("form") as HTMLFormElement;
        form.setAttribute("method", method?.toLowerCase());
        form.setAttribute("target", "_self");
        form.setAttribute("action", url);
        if (Utils.isNotNull(parameters)) {
            for (const parameter in parameters) {
                // eslint-disable-next-line no-prototype-builtins
                if (parameters.hasOwnProperty(parameter)) {
                    const param = document.createElement("input");
                    param.setAttribute("type", "hidden");
                    param.setAttribute("name", parameter);
                    param.setAttribute("value", parameters[parameter]);
                    form.appendChild(param);
                }
            }
        }
        body.appendChild(form);
        form.submit();
    }

    public static post(url: string, parameters?: { [key: string]: string }): void {
        SubmitForm.submit(url, FormMethod.POST, parameters);
    }

    public static get(url: string, parameters?: { [key: string]: string }): void {
        SubmitForm.submit(url, FormMethod.GET, parameters);
    }
}
