import Utils from "platform/util/Utils";
import WebUtil from "platform/util/WebUtil";

const UrlQueryStringRaw: string = window.location.search;
const UrlKycContext: boolean = Utils.parseBoolean(WebUtil.urlParam("kycContext"));
const UrlBackNavigation: boolean = Utils.parseBoolean(WebUtil.urlParam("backNavigation"));
const UrlAmount: number = Utils.parseNumber(WebUtil.urlParam("amount")) || Utils.parseNumber(WebUtil.urlParam("packageAmount"));
const UrlCurrency: string = WebUtil.urlParam("currency");
const UrlVerifyCCGuid: string = WebUtil.urlParam("depositRequestGuid");
const UrlVerifyCCEKey: string = WebUtil.urlParam("encryptionKey");
const UrlVerifyAPM: boolean = Utils.parseBoolean(WebUtil.urlParam("eVerify"));
const UrlWebView: string = WebUtil.urlParam("webview");
const UrlHidePlatformButton: boolean = Utils.parseBoolean(WebUtil.urlParam("hideplatformbutton")); // Indicate that Deposit page was opened in browser from native app

export {
    UrlQueryStringRaw,
    UrlKycContext,
    UrlBackNavigation,
    UrlAmount,
    UrlCurrency,
    UrlVerifyCCGuid,
    UrlVerifyCCEKey,
    UrlVerifyAPM,
    UrlWebView,
    UrlHidePlatformButton
};
