export enum GetUrlType {

    Deposit = 1,
    Profit = 2,
    ProfessionalServiceAgreement = 3,
    Documents = 4,
    KYC = 5,
    ProfessionalAccount = 6,
    DepositLimits = 7,
    ShareInfo = 8,
}
