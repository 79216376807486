import Utils from "platform/util/Utils";

export default class GraphNode<T> {

    private readonly _parent: GraphNode<T>;
    private _value: T;
    private _nodes: Array<GraphNode<T>> = [];

    constructor(value: T, parent?: GraphNode<T>) {
        this._value = value;
        this._parent = parent;
    }

    public addNode(value: T, parent?: GraphNode<T>): GraphNode<T> {
        const node: GraphNode<T> = GraphNode.create<T>(value, parent || this);
        this._nodes.push(node);
        return node;
    }

    public get nodes(): Array<GraphNode<T>> {
        return this._nodes;
    }

    public static create<T>(value: T, parent?: GraphNode<T>): GraphNode<T> {
        return new GraphNode<T>(value, parent);
    }

    public set value(value: T) {
        this._value = value;
    }

    public get value(): T {
        return this._value;
    }

    public get parent(): GraphNode<T> {
        return this._parent;
    }

    public hasNode(value: T): boolean {
        if (this._value === value) {
            return true;
        }
        for (const node of this._nodes) {
            if (node.value === value) {
                return true;
            }
        }
        return this._parent ? this._parent.hasNode(value) : false;
    }

    public filter(condition: (t: T) => boolean): void {
        this._nodes = this._nodes.filter((node: GraphNode<T>) => condition(node.value));
    }

    public static search<T>(condition: (t: T) => boolean, node?: GraphNode<T>): GraphNode<T> {
        const from: GraphNode<T> = node;
        if (condition(from.value)) {
            return from;
        } else if (Utils.isArrayNotEmpty(from.nodes)) {
            for (let i = 0; i < from.nodes.length; i++) {
                const result = this.search(condition, from.nodes[i]);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    public static iterate<T>(node: GraphNode<T>, consumer: (node: GraphNode<T>, parentNode: GraphNode<T>) => void, parentNode?: GraphNode<T>): void {
        if (node) {
            consumer(node, parentNode);
            if (Utils.isArrayNotEmpty(node.nodes)) {
                node.nodes.forEach((childNode: GraphNode<T>) => {
                    GraphNode.iterate(childNode, consumer, node);
                });
            }
        }
    }
}
