export enum EventType {

    Click = "click",
    Message = "message",
    BeforeUnload = "beforeunload",
    Load = "load",
    OnLoad = "onload",
    OrientationChange = "orientationchange",
    Resize = "resize",
}
