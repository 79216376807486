import Utils from "platform/util/Utils";
import {Http, HttpHeader, HttpMethod, HttpReject} from "platform/network/http/Http";
import {ServerType} from "platform/enum/ServerType";
import Platform from "platform/Platform";
import {LSKey} from "platform/storage/Storage";
import {TSMap} from "typescript-map";
import {Environment} from "platform/enum/Environment";
import {XhrHeaders} from "platform/network/xhr/XhrHeaders";

const LocalHostPaths: TSMap<ServerType, string> = new TSMap();
LocalHostPaths.set(ServerType.Login, "https://localhost/LoginSite/");
LocalHostPaths.set(ServerType.Compliance, "https://localhost/");
LocalHostPaths.set(ServerType.TradingDemo, "https://localhost/");
LocalHostPaths.set(ServerType.TradingReal, "https://localhost/");

export class Xhr {

    private constructor() {
    }

    public static async sendTo(server: ServerType, request: any = {}, path: string, token: string = null, plain: boolean = false, headers?: HttpHeader[], withCredentials?: boolean, timeout?: number): Promise<any> {
        return this.sendToInternal(HttpMethod.POST, server, request, path, token, plain, headers, withCredentials, timeout);
    }

    public static async sendGetTo(server: ServerType, path: string, token: string = null, headers?: HttpHeader[], timeout?: number): Promise<any> {
        return this.sendToInternal(HttpMethod.GET, server, null, path, token, null, headers, null, timeout);
    }

    private static async sendToInternal(method: HttpMethod, server: ServerType, request: any = {}, path: string, token: string = null, plain: boolean = false, headers?: HttpHeader[], withCredentials?: boolean, timeout?: number): Promise<any> {
        const servers: {[key: string]: string} = Platform.config().servers;
        Utils.checkNotNull(servers);
        let serverUrl: string = servers[server];
        if (Environment.isDev()) {
            const stl: string = await Platform.storage().getItem(LSKey.ServerToLocalhost);
            if (stl === "true") {
                serverUrl = LocalHostPaths.get(server);
            }
        }
        Utils.checkNotNull(serverUrl);
        return this.sendInternal(method, serverUrl, request, path, token, plain, headers, withCredentials, timeout);
    }

    public static async send(url: string, request: any = {}, path: string, token: string = null, plain: boolean = false, headers: HttpHeader[], withCredentials: boolean, timeout?: number): Promise<any> {
        return Xhr.sendInternal(HttpMethod.POST, url, request, path, token, plain, headers, withCredentials, timeout);
    }

    public static async sendGet(url: string, path: string, token: string = null, headers: HttpHeader[], timeout?: number): Promise<any> {
        return Xhr.sendInternal(HttpMethod.GET, url, null, path, token, null, headers, null, timeout);
    }

    private static async sendInternal(method: HttpMethod, url: string, request: any = {}, path: string, token: string = null, plain: boolean = false, headers: HttpHeader[], withCredentials: boolean, timeout?: number): Promise<any> {
        const requestHeaders: HttpHeader[] = XhrHeaders.Value(token, headers);
        let answer: [HttpReject, any];
        if (method === HttpMethod.GET) {
            answer = await Utils.to(Http.get(url + path, requestHeaders, timeout));
        } else {
            answer = await Utils.to(Http.post(url + path, plain ? request : JSON.stringify(request), requestHeaders, withCredentials, timeout));
        }
        const error: HttpReject = answer[0];
        if (error) {
            return Promise.reject(error);
        } else {
            try {
                return Promise.resolve(JSON.parse(answer[1].trim()));
            } catch (e) {
                return Promise.resolve(null);
            }
        }
    }
}
