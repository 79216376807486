import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";

export class IntegrationGetInMemoryValue implements IntegrationMessage {

    public classId: number = IntegrationClassId.GET_IN_MEMORY_VALUE;
    public key: string;

    public constructor(key: string) {
        this.key = key;
    }
}
