import {Action, ReducerBuilder} from "redux-ts";
import {Dispatch, Middleware, MiddlewareAPI} from "redux";
import {TSMap} from "typescript-map";
import {Logger} from "platform/logger/Logger";
import Utils from "platform/util/Utils";

export abstract class Reducer<State> {

    protected _logger: Logger = Logger.Of(this.constructor.toString().match(/\w+/g)[1]);
    protected _middlewareActions: TSMap<string, (payload: any, s?: State) => void> = new TSMap<string, (a: any, s?: State) => void>();
    protected _reducer: ReducerBuilder<State>;

    protected constructor() {
        const builder: ReducerBuilder<State> = new ReducerBuilder<State>();
        this.setup(builder);
        this._reducer = builder;
    }

    protected abstract setup(builder: ReducerBuilder<State>): void;

    public abstract get name(): string;

    public get reducer(): ReducerBuilder<State> {
        return this._reducer;
    }

    public middleware(): Middleware {
        if (Utils.isMapNotEmpty(this._middlewareActions)) {
            return (api: MiddlewareAPI<any>) => (next: Dispatch<Action>) => <A extends Action>(action: A): A => {
                const _middlewareAction = this._middlewareActions.get(action.type);
                if (Utils.isNotNull(_middlewareAction)) {
                    _middlewareAction(action.payload, api.getState() as any);
                }
                return next(action);
            };
        }
        return null;
    }
}
