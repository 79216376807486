import WebUtil from "platform/util/WebUtil";

enum OrientationType {

    PORTRAIT = "portrait",
    LANDSCAPE = "landscape",
}

namespace OrientationType {

    export function landscape(orientation: OrientationType): boolean {
        return WebUtil.isMobile() && orientation === OrientationType.LANDSCAPE;
    }
}

export {
    OrientationType
};
