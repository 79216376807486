import Utils from "platform/util/Utils";

export class Increment {

    private readonly _reverse: boolean;
    private _count: number;

    private constructor(initialValue?: number, reverse?: boolean) {
        this._count = Utils.isNotNull(initialValue) ? initialValue : 0;
        this._reverse = reverse;
    }

    public static from(initialValue?: number, reverse?: boolean): Increment {
        return new Increment(initialValue, reverse);
    }

    public next(): number {
        if (this._reverse) {
            this._count--;
        } else {
            this._count++;
        }
        return this._count;
    }
}
