import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";

export class IntegrationLog implements IntegrationMessage {

    public classId: number = IntegrationClassId.LOG;
    public log: string;

    public constructor(log: string) {
        this.log = log;
    }
}
