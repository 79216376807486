import {ActionCreatorDefinition, createAction} from "redux-ts";
import {NetworkType} from "platform/network/Network";
import {NetworkStatus} from "platform/enum/NetworkStatus";

export const SetNetworkStatusType: string =  "SetNetworkStatus";

export interface SetNetworkStatusPayload {

    networkType: NetworkType;
    status: NetworkStatus;
}

export const SetNetworkStatus: ActionCreatorDefinition<SetNetworkStatusPayload, any> = createAction<SetNetworkStatusPayload>(SetNetworkStatusType);
