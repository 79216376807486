import {ConnectionListener} from "platform/listener/ConnectionListener";
import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationConnection} from "platform/integration/connection/IntegrationConnection";
import {WebWindow} from "platform/integration/win/WebWindow";
import Utils from "platform/util/Utils";
import WebUtil from "platform/util/WebUtil";
import {BaseEnvironment} from "platform/environment/BaseEnvironment";
import {Storage} from "platform/storage/Storage";
import {DummyStorage} from "platform/storage/DummyStorage";
import {PostMessageConnectionProvider} from "platform/integration/connection/PostMessageConnectionProvider";
import {Navigator} from "platform/navigator/Navigator";
import {WebNavigator} from "platform/navigator/WebNavigator";
import {Audio} from "platform/audio/Audio";
import {WebAudio} from "platform/audio/WebAudio";
import {EventType} from "platform/enum/EventType";
import {EnvType} from "platform/enum/EnvType";
import {WebStorage} from "platform/storage/WebStorage";
import {WebSessionStorage} from "platform/storage/WebSessionStorage";
import {BITracker} from "platform/analytics/BITracker";
import {BITrackerWeb} from "platform/analytics/BITrackerWeb";

export default class WebEnvironment extends BaseEnvironment {

    private static _instance: WebEnvironment;
    private readonly _supportStorage: boolean;

    private constructor() {
        super();
        try {
            const storage: any = window.localStorage;
            this._supportStorage = Utils.isNotNull(storage);
        } catch (e) {
            this._logger.error("Local storage not supported");
        }
    }

    public static instance(): WebEnvironment {
        return this._instance || (this._instance = new this());
    }

    public type(): EnvType {
        return EnvType.Web;
    }

    public storage(): Storage {
        if (this._supportStorage) {
            return WebStorage.instance();
        } else {
            return DummyStorage.instance();
        }
    }

    public sessionStorage(): Storage {
        if (this._supportStorage) {
            return WebSessionStorage.instance();
        } else {
            return DummyStorage.instance();
        }
    }

    public createConnection(win: WebWindow, listener: ConnectionListener<IntegrationMessage>): IntegrationConnection<IntegrationMessage> {
        return PostMessageConnectionProvider.instance().createConnection(win, listener);
    }

    public openWindow(url: string, target?: string, params?: string): WebWindow {
        if (Utils.isNotEmpty(url)) {
            const win: Window = window.open(url === "empty" ? "" : url, target, params);
            if (Utils.isNotNull(win)) {
                return WebUtil.winProxy(win);
            }
        } else {
            this._logger.warn("Can't open empty url");
        }
        return null;
    }

    public closeSelf(): void {
        open("", "_self").close();
        if (!window.closed) {
            throw new Error("Can't close self");
        }
    }

    public redirect(url: string): void {
        if (Utils.isNotEmpty(url)) {
            window.location.href = url;
        } else {
            this._logger.warn("can't redirect to empty url.");
        }
    }

    public navigator(): Navigator {
        return WebNavigator.instance();
    }

    public audio(): Audio {
        return WebAudio.instance();
    }

    public bi(): BITracker {
        return BITrackerWeb.instance();
    }

    public addEventListener(type: EventType, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
        window.addEventListener(type, listener, options);
    }

    public removeEventListener(type: EventType, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
        window.removeEventListener(type, listener, options);
    }
}
