export enum ServerType {

    PleXop = "pleXop",
    Login = "login",
    Compliance = "compliance",
    TradingDemo = "tradingDemo",
    TradingReal = "tradingReal",
    Billing = "billing",
    Chat = "chat",
    Api = "api",
    CES = "ces",
    Sara = "sara"
}
