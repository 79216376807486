import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";

export interface CloseMeParameters {

    confirm?: boolean;
}

export class IntegrationWinClose implements IntegrationMessage {

    public classId: number = IntegrationClassId.WIN_CLOSE;
    public readonly parameters: CloseMeParameters;

    public constructor(parameters?: CloseMeParameters) {
        this.parameters = parameters;
    }
}
