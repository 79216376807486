import AsyncComponent from "platform/component/async/AsyncComponent";

export const DepositAsync = AsyncComponent(() => {
    return import("component/root/DepositRoot");
});

export const DepositMobileAsync = AsyncComponent(() => {
    return import("component/root/DepositRootMob");
});

