import {Middleware, ReducersMapObject, Store} from "redux";
import {StoreBuilder} from "redux-ts";
import initSubscriber from "redux-subscriber";
import {Reducer} from "platform/redux/Reducer";
import Utils from "platform/util/Utils";
import {PlatformReduxState} from "platform/redux/PlatformReduxState";
import {Environment} from "platform/enum/Environment";

export class ReduxStore<State extends PlatformReduxState> {

    private readonly _store: Store<State>;
    private readonly _subscriber: (path: string, listener: (s: State) => void) => void;

    public constructor(reducersMapObject: ReducersMapObject, reducers: Reducer<any>[], middleWares: Middleware[], initialState?: State) {
        const builder: StoreBuilder<State> = new StoreBuilder<State>();
        if (Utils.isArrayNotEmpty(middleWares)) {
            middleWares.forEach((middleware: Middleware) => {
                if (Utils.isNotNull(middleware)) {
                    builder.withMiddleware(middleware);
                }
            });
        }
        if (Utils.isArrayNotEmpty(reducers)) {
            reducers.forEach((reducer: Reducer<any>) => {
                const middleware: Middleware = reducer.middleware();
                if (Utils.isNotNull(middleware)) {
                    builder.withMiddleware(middleware);
                }
                builder.withReducerBuilder(reducer.name, reducer.reducer);
            });
        }
        builder.withReducersMap(reducersMapObject || {});
        if (Utils.isNotNull(initialState)) {
            builder.withInitialState(initialState);
        }
        if (!Environment.isProd()) {
            builder.withDevTools();
        }
        this._store = builder.build();
        this._subscriber = initSubscriber(this._store);
    }

    public store(): Store<State> {
        return this._store;
    }

    public subscribe(listener: (s: State) => void, path?: string): () => void {
        if (Utils.isNotNull(listener)) {
            return (this._subscriber(Utils.isNotEmpty(path) ? path : "", listener) as any);
        }
        return null;
    }
}
