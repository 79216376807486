import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import DepositEngine from "core/engine/DepositEngine";
import DepositReducer from "core/redux/deposit/DepositReducer";

export class DepositService implements Service<DepositEngine, any> {

    private static _instance: DepositService;

    private constructor() {
    }

    public static instance(): DepositService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Deposit;
    }

    public reducers(): Array<Reducer<any>> {
        return [DepositReducer.instance()];
    }

    public engine(): DepositEngine {
        return DepositEngine.instance();
    }

    public state(): any {
        return undefined;
    }
}
