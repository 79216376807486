import Utils from "platform/util/Utils";
import WebUtil from "platform/util/WebUtil";

export interface HttpHeader {
    name: string;
    value: string;
}

export enum HttpMethod {

    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    UPDATE = "UPDATE",
    DELETE = "DELETE"
}

export interface HttpReject {

    status?: number;
    response?: any;
}

interface HttpResponse<T> extends Response {

    parsedBody?: T;
}

async function get<T>(
    path: string,
    args: RequestInit
): Promise<HttpResponse<T>> {
    return await http<T>(new Request(path, {...(args || {}), ...{ method: "get", headers: { "Content-Type" : "application/json" } }}));
}

async function post<T>(
    path: string,
    body: any,
    args: RequestInit
): Promise<HttpResponse<T>> {
    return await http<T>(new Request(path, {...(args || {}), ...{ method: "post", body: JSON.stringify(body), headers: { "Content-Type" : "application/json" } }}));
}

async function http<T>(
    request: RequestInfo
): Promise<HttpResponse<T>> {
    const response: HttpResponse<T> = await fetch(
        request
    );
    response.parsedBody = await response.json();
    return response;
}

export class Http {

    private constructor() {}

    public static async get(url: string, headers?: HttpHeader[], timeout?: number): Promise<any> {
        return this.execute(url, HttpMethod.GET, null, headers, false, timeout);
    }

    public static async post(url: string, data?: any, headers?: HttpHeader[], withCredentials?: boolean, timeout?: number): Promise<any> {
        return this.execute(url, HttpMethod.POST, data, headers, withCredentials, timeout);
    }

    public static async getJson(url: string, headers?: HttpHeader[], timeout?: number): Promise<any> {
        const arr: HttpHeader[] = Utils.isArrayNotEmpty(headers) ? headers : [];
        arr.push({name: "Content-Type", value: "application/json"});
        const URL: string = WebUtil.addGetParam(url, "timestamp", new Date().getTime().toString());
        const answer: any = await Utils.to(this.execute(URL, HttpMethod.GET, null, arr, false, timeout));
        if (answer[0]) {
            return Promise.reject(answer[0]);
        } else {
            return Promise.resolve(JSON.parse(answer[1]));
        }
    }

    public static async getCSV(url: string, headers?: HttpHeader[]): Promise<any> {
        const arr: HttpHeader[] = Utils.isArrayNotEmpty(headers) ? headers : [];
        arr.push({name: "Content-Type", value: "text/csv"});
        const URL: string = WebUtil.addGetParam(url, "timestamp", new Date().getTime().toString());
        const answer: any = await Utils.to(this.execute(URL, HttpMethod.GET, null, arr));
        if (answer[0]) {
            return Promise.reject(answer[0]);
        } else {
            return Promise.resolve(answer[1]);
        }
    }

    public static async postJson(url: string, data?: any, headers?: HttpHeader[], timeout?: number): Promise<any> {
        const arr: HttpHeader[] = Utils.isArrayNotEmpty(headers) ? headers : [];
        arr.push({name: "Content-Type", value: "application/json"});
        const answer: any = await Utils.to(this.execute(url, HttpMethod.POST, data, arr, false, timeout));
        if (answer[0]) {
            return Promise.reject(answer[0]);
        } else {
            return Promise.resolve(JSON.parse(answer[1]));
        }
    }
    public static async deleteJson(url: string, headers?: HttpHeader[]): Promise<any> {
        const arr: HttpHeader[] = Utils.isArrayNotEmpty(headers) ? headers : [];
        arr.push({name: "Content-Type", value: "application/json"});
        const URL: string = WebUtil.addGetParam(url, "timestamp", new Date().getTime().toString());
        const answer: any = await Utils.to(this.execute(URL, HttpMethod.DELETE, null, arr));
        if (answer[0]) {
            return Promise.reject(answer[0]);
        } else {
            return Promise.resolve(JSON.parse(answer[1]));
        }
    }

    public static async execute(url: string, method: HttpMethod, data?: any, headers?: HttpHeader[], withCredentials?: boolean, timeout?: number): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                const hs: {[key: string]: string} = {};
                if (Utils.isNotNull(headers)) {
                    headers.map((header: HttpHeader) => {
                        hs[header.name] = header.value;
                    });
                }
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), timeout || 60000);
                fetch(new Request(url, {...(withCredentials ? {credentials: "include"} : {}),
                        ...{method, body: data, headers: hs, signal: controller.signal}})).then((response: Response) => {
                    clearTimeout(timeoutId);
                    response.text().then((text: string) => {
                        if (response.status === 200) {
                            resolve(text);
                        } else {
                            reject({status: response.status, response: text});
                        }
                    }).catch(() => {
                        reject({status: 500});
                    });
                }).catch(() => {
                    clearTimeout(timeoutId);
                    reject({status: 500});
                });
            }
        );
    }

    public static async getWithCredentials(url: string): Promise<any> {
        const response = await get(url, {credentials: "include"});
        if (response.status === 200) {
            return Promise.resolve(response.parsedBody);
        } else {
            const reject: HttpReject = {
                status: response.status,
                response: response.parsedBody
            };
            return Promise.reject(reject);
        }
    }

    public static async postWithCredentials(url: string, data?: any): Promise<any> {
        const response = await post(url, data, {credentials: "include"});
        if (response.status === 200) {
            return Promise.resolve(response.parsedBody);
        } else {
            const reject: HttpReject = {
                status: response.status,
                response: response.parsedBody
            };
            return Promise.reject(reject);
        }
    }
}
