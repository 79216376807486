import {TSMap} from "typescript-map";
import moment from "moment-mini";

export const UNDEFINED_VALUE: string = "-";

export default class Utils {

    private constructor() {
    }

    public static isNumber(value: string): boolean {
        return /^\d+$/.test(value);
    }

    public static isFloatNumber(value: string): boolean {
        return /^-?[0-9]+\.?[0-9]*$/.test(value) || /^-?[0-9]+\,?[0-9]*$/.test(value);
    }

    public static parseNumber(v: string): number {
        if (Utils.isNotEmpty(v)) {
            const result: number = parseFloat(v);
            if (isNaN(result)) {
                return null;
            }
            return result;
        }
        return null;
    }

    public static escapeRegExp(v: string): string {
        if (Utils.isNotEmpty(v)) {
            return v.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        }
        return v;
    }

    public static parseBoolean(v: string | boolean): boolean {
        return v === "true" || v === "True" || v === true ? true : v === "false" || v === "False" || v === false ? false : null;
    }

    public static parse(v: string): any {
        let result: any = Utils.parseNumber(v);
        if (Utils.isNotNull(result)) {
            return result;
        }
        result = Utils.parseBoolean(v);
        if (Utils.isNotNull(result)) {
            return result;
        }
        return v;
    }

    public static countDecimals(val: number): number {
        if(Math.floor(val) === val) return 0;
        return val.toString().split(".")[1]?.length || 0;
    }

    public static nullTo<T>(val: T, def: T): T {
        return this.isNotNull(val) ? val : def;
    }

    public static nullTo0(val: number): number {
        return this.isNotNull(val) ? val : 0;
    }

    public static nullToUndefined(val: number): number | string {
        return this.isNotNull(val) ? val : UNDEFINED_VALUE;
    }

    public static nullToEmpty(val: string): string {
        return this.isNotEmpty(val) ? val : "";
    }

    public static isNotEmpty(val: string): boolean {
        return typeof val !== "undefined" && val !== null && val.length > 0;
    }

    public static isNotNull(val: any): boolean {
        return typeof val !== "undefined" && val !== null;
    }

    public static isNull(val: any): boolean {
        return typeof val === "undefined" || val === null;
    }

    public static isArrayNotEmpty(val: any[]): boolean {
        return typeof val !== "undefined" && val !== null && val.length > 0;
    }

    public static isArrayEmpty(val: any[]): boolean {
        return typeof val === "undefined" || val === null || val.length === 0;
    }

    public static isObjectEmpty(val: any): boolean {
        return Utils.isNull(val) || Object.keys(val).length === 0 && val.constructor === Object;
    }

    public static isMapNotEmpty(val: TSMap<any, any>): boolean {
        return typeof val !== "undefined" && val !== null && (val.size() > 0 || val.keys().length > 0);
    }

    public static isMapEmpty(val: TSMap<any, any>): boolean {
        return typeof val === "undefined" || val === null || val.size() === 0 || val.keys().length === 0;
    }

    public static capitalize = (v: string): string => {
        if (v) {
            return `${v[0].toUpperCase()}${v.slice(1)}`;
        }
        return v;
    }

    public static objectToMap<K, V>(obj: any): TSMap<K, V> {
        if (Utils.isNotNull(obj)) {
            const map: TSMap<K, V> = new TSMap();
            Object.keys(obj).forEach((key: any) => {
                map.set(key, obj[key]);
            });
            return map;
        }
        return null;
    }

    public static arrayToMap<K, V>(array: V[], key: (v: V) => K): TSMap<K, V> {
        const map: TSMap<K, V> = new TSMap();
        if (Utils.isNotNull(array) && Utils.isNotNull(key)) {
            array.forEach((v: V) => {
                map.set(key(v), v);
            });
        }
        return map;
    }

    public static greaterThen0(val: number): boolean {
        return this.isNotNull(val) && val > 0;
    }

    public static not0(val: number): boolean {
        return this.isNotNull(val) && val !== 0;
    }

    public static lessThen0(val: number): boolean {
        return this.isNotNull(val) && val < 0;
    }

    public static compareUndefined(o1: any, o2: any): number {
        if (this.isNotNull(o1) && this.isNull(o2)) {
            return -1;
        }
        if (this.isNull(o1) && this.isNotNull(o2)) {
            return 1;
        }
        return 0;
    }

    public static compareArrayLength<T>(o1: T[], o2: T[]): number {
        const result: number = Utils.compareUndefined(o1, o2);
        if (result === 0 && Utils.isNotNull(o1) && Utils.isNotNull(o2)) {
            return o1.length < o2.length ? -1 : (o1.length > o2.length ? 1 : 0);
        }
        return result;
    }

    public static compareObject(a: { [key: string]: any }, b: { [key: string]: any }) {
        for (const key in a) {
            if (!(key in b) || a[key] !== b[key]) {
                return false;
            }
        }
        for (const key in b) {
            if (!(key in a) || a[key] !== b[key]) {
                return false;
            }
        }
        return true;
    }

    public static compareArrays<T>(arr1: T[], arr2: T[], comparator: (t1: T, t2: T) => number): number {
        Utils.checkNotNull(comparator);
        let result: number = this.compareArrayLength(arr1, arr2);
        if (result === 0 && Utils.isNotNull(arr1) && Utils.isNotNull(arr2)) {
            for (let i = 0; i < arr1.length; i++) {
                result = comparator(arr1[i], arr2[i]);
                if (result !== 0) {
                    break;
                }
            }
        }
        return result;
    }

    public static compareNumber(o1: number, o2: number): number {
        const result: number = this.compareUndefined(o1, o2);
        if (result === 0 && this.isNotNull(o1) && this.isNotNull(o2)) {
            return o1 === o2 ? 0 : o1 < o2 ? -1 : 1;
        }
        return result;
    }

    public static compareString(o1: string, o2: string): number {
        const result: number = Utils.compareUndefined(o1, o2);
        if (result === 0 && Utils.isNotNull(o1) && Utils.isNotNull(o2)) {
            return o1 < o2 ? -1 : (o1 > o2 ? 1 : 0);
        }
        return result;
    }

    public static compareDate(o1: string, o2: string): number {
        const firstDate = moment(o1)
        const secondDate = moment(o2)
        const result: number = Utils.compareUndefined(o1, o2);
        if (result === 0 && Utils.isNotNull(o1) && Utils.isNotNull(o2)) {
            return o1 === o2 ? 0 : firstDate.isAfter(secondDate) ? 1 : -1;
        }
        return result;
    }

    public static compareBoolean(b1: boolean, b2: boolean): number {
        if (b1 && !b2) {
            return -1;
        }
        if (!b1 && b2) {
            return 1;
        }
        return 0;
    }

    public static has(array: any[], val: any): boolean {
        if (Utils.isArrayNotEmpty(array) && Utils.isNotNull(val)) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] === val) {
                    return true;
                }
            }
        }
        return false;
    }

    public static isEmpty(val: string): boolean {
        return typeof val === "undefined" || val === null || val.length === 0;
    }

    public static checkNotNull<T>(reference: T, msg?: string): T {
        if (Utils.isNull(reference)) {
            throw new ReferenceError(msg);
        }
        return reference;
    }

    public static randomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public static range(from: number, to: number): number[] {
        return Array(to - from + 1).fill(null).map((_, idx) => from + idx);
    }

    public static pad(v: number): string {
        return ("0" + v).slice(-2);
    }

    public static remove<T>(arr: T[], item: T): boolean {
        if (Utils.isArrayNotEmpty(arr) && item) {
            for (let i: number = 0; i < arr.length; i++) {
                if (arr[i] === item) {
                    arr.splice(i, 1);
                    return true;
                }
            }
        }
        return false;
    }

    public static replace<T, I>(arr: T[], item: T, id: (t: T) => I, merge: boolean = true): boolean {
        if (Utils.isArrayNotEmpty(arr) && item && id) {
            for (let i: number = 0; i < arr.length; i++) {
                const t: T = arr[i];
                if (id(t) === id(item)) {
                    arr[i] = merge ? {
                        ...t,
                        ...item
                    } : item;
                    return true;
                }
            }
        }
        return false;
    }

    public static replaceAll(value: string, regex: string, replacement: string): string {
        if (Utils.isNotNull(value)) {
            return value.replace(new RegExp(Utils.escapeRegExp(regex), "g"), replacement);
        }
        return value;
    }

    public static to<T, U = any>(
        promise: Promise<T>,
        errorExt?: object
    ): Promise<[U | null, T | undefined]> {
        return promise
            ?.then<[null, T]>((data: T) => [null, data])
            ?.catch<[U, undefined]>((err) => {
                if (errorExt) {
                    Object.assign(err, errorExt);
                }
                return [err, undefined];
            });
    }

    public static isObject(item: any): boolean {
        return (item && typeof item === "object" && !Array.isArray(item));
    }

    public static merge(target: any, ...sources: any[]): any {
        if (!sources.length) {
            return target;
        }
        const source = sources.shift();
        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) {
                        Object.assign(target, { [key]: {} });
                    }
                    this.merge(target[key], source[key]);
                } else if (Array.isArray(source[key])) {
                    Object.assign(target, { [key]: source[key] });
                } else if (typeof source[key] !== "undefined") {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }
        return this.merge(target, ...sources);
    }

    public static deepCopy(source: object): object {
        if (Utils.isNotNull(source)) {
            return JSON.parse(JSON.stringify(source));
        }
        return null;
    }

    public static EnumKeyByValue = <R extends (string | number), T extends {[key: string] : R}>(myEnum: T, enumValue: any): string => {
        const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
        return keys.length > 0 ? keys[0] : "";
    }

    public static uuid(): string {
        let a: any;
        let b: any;
        for (b = a = ""; a++ < 36; b += a * 51 & 52 ? (a ^ 15 ? 8 ^ Math.random() * (a ^ 20 ? 16 : 4) : 4).toString(16) : "-") {}
        return b;
    }

    public static uuidValid(uuid: string): boolean {
        const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return pattern.test(uuid);
    }

    public static isStringLatin(value: string) {
        return (/^[a-zA-Z0-9\s.,!?%$^'@#&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/.test(value))
    }

    public static isNumberInt32(input) {
        
        return Number(input) <= 2147483647 && Number(input) >=-2147483648;
    }

    public static isValidJson(value: string) {
        try {
            JSON.parse(value);
        } catch (e) {
            return false;
        }
        return true;
    }

}
