import WebUtil from "platform/util/WebUtil";
import Platform from "platform/Platform";
import {LSKey} from "platform/storage/Storage";
import Utils from "platform/util/Utils";
import {Logger} from "platform/logger/Logger";

const Token: string = WebUtil.urlParam("token") || WebUtil.urlParam("tokenId");

export class TokenManager {

    private static _logger: Logger = Logger.Of("TokenManager");

    private constructor() {}

    public static async token(): Promise<string> {
        const token: string = await Platform.storage().getItem(LSKey.Token);
        if (Utils.isNotEmpty(Token) && Utils.uuidValid(Token) && (Utils.isEmpty(token) || token !== Token)) {
            Platform.storage().setItem(LSKey.Token, Token);
            return Token;
        }
        return Promise.resolve(token);
    }

    public static clear() {
        this._logger.debug("Clear");
        Platform.storage().removeItem(LSKey.Token);
    }
}
