enum PaymentMethod {

    CreditCard = "credit-card",
    OtherCreditCard = "other-credit-card",
    WireTransfer = "wire-transfer",
    PayRetailers = "pay-retailers",
}

export {
    PaymentMethod
};
