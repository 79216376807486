import Utils from "platform/util/Utils";
import {Logger} from "platform/logger/Logger";
import {IntegrationBridge} from "platform/integration/bridge/IntegrationBridge";
import {IntegrationBridgeType} from "platform/integration/bridge/IntegrationBridgeType";
import {WebWindow} from "platform/integration/win/WebWindow";

/*
*  NOTE: IntegrationBridgeType.ELECTRON Will be use inside electron environment definition in order to not compile in WEB bundle
* */
export default class IntegrationBridgeFactory {

    private static _logger: Logger = Logger.Of("IntegrationBridgeFactory");

    private constructor() {}

    public static createBridge(sender: any, receiver: WebWindow, bridgeType?: IntegrationBridgeType): IntegrationBridge {
        let useBridgeType: IntegrationBridgeType = bridgeType;
        if (Utils.isNull(useBridgeType)) {
            if (Utils.isNotNull(sender.ReactNativeWebView)) {// window.ReactNativeWebView will be set by React Native web view to listener function from Native side
                useBridgeType = IntegrationBridgeType.REACT_NATIVE;
            } else if (Utils.isNotNull(sender.AndroidBridge)) {// window.AndroidBridge will be set by Android wrapper to listener function from Native side
                useBridgeType = IntegrationBridgeType.ANDROID;
            } else if (sender.webkit && sender.webkit.messageHandlers && sender.webkit.messageHandlers.postMessageHandler) {
                useBridgeType = IntegrationBridgeType.IOS;
            } else if (receiver) {// html5 post message
                useBridgeType = IntegrationBridgeType.HTML5;
            } else {
                useBridgeType = IntegrationBridgeType.DUMMY
            }
        }
        return IntegrationBridgeFactory.createBridgeInternal(sender, receiver, useBridgeType);
    }

    private static createBridgeInternal(sender: any, receiver: WebWindow, bridgeType: IntegrationBridgeType): IntegrationBridge {
        switch (bridgeType) {
            case IntegrationBridgeType.REACT_NATIVE:
                this._logger.debug("Create React Native Bridge");
                return new (class ReactNativePostMessageBridge extends IntegrationBridge {

                    public type(): IntegrationBridgeType {
                        return IntegrationBridgeType.REACT_NATIVE;
                    }

                    public sendMessage(message: string): void {
                        sender.ReactNativeWebView.postMessage(message);
                    }
                })();
            case IntegrationBridgeType.ANDROID:
                this._logger.debug("Create Android Bridge");
                return new (class AndroidPostMessageBridge extends IntegrationBridge {

                    public type(): IntegrationBridgeType {
                        return IntegrationBridgeType.ANDROID;
                    }

                    public sendMessage(message: string): void {
                        sender.AndroidBridge.sendMessage(message);
                    }
                })();
            case IntegrationBridgeType.IOS:
                this._logger.debug("Create iOS Bridge");
                return new (class IOSPostMessageBridge extends IntegrationBridge {
                    public type(): IntegrationBridgeType {
                        return IntegrationBridgeType.IOS;
                    }

                    public sendMessage(message: string): void {
                        sender.webkit.messageHandlers.postMessageHandler.postMessage(message);
                    }
                })();
            case IntegrationBridgeType.HTML5:
                this._logger.debug("Create HTML5 Bridge");
                return new (class HTML5PostMessageBridge extends IntegrationBridge {

                    public type(): IntegrationBridgeType {
                        return IntegrationBridgeType.HTML5;
                    }

                    public sendMessage(message: string): void {
                        receiver.postMessage(message, "*");
                    }

                })();
            default:
                return new (class BridgeStub extends IntegrationBridge {

                    private _logger: Logger = Logger.Of("BridgeStub");

                    public type(): IntegrationBridgeType {
                        return IntegrationBridgeType.DUMMY;
                    }

                    public sendMessage(message: string): void {
                        this._logger.info("Send message: " + message);
                    }
                })();
        }
    }
}
