import {Route} from "router5";
import {PageType} from "enum/PageType";

export class Routes {

    private static ROUTES: Route[] = [
        {name: PageType.DepositTopUp, path: "/deposit-top-up?kycContext&backNavigation&webview&theme&eVerify"},
        {name: PageType.DepositSuccessful, path: "/deposit-successful?kycContext&backNavigation&webview&theme&eVerify"}
    ];

    private constructor() {
    }

    public static routes(): Route[] {
        return this.ROUTES;
    }
}
