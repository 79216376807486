import {Storage} from "platform/storage/Storage";

export class WebSessionStorage implements Storage {

    private static _instance: Storage;

    private constructor() {
    }

    public static instance(): Storage {
        return this._instance || (this._instance = new this());
    }

    public setItem(key: string, data: string): void {
        window.sessionStorage.setItem(key, data);
    }

    public getItem(key: string): Promise<string | any> {
        return Promise.resolve(window.sessionStorage.getItem(key));
    }

    public removeItem(key: string): void {
        window.sessionStorage.removeItem(key);
    }
}
