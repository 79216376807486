import Utils from "platform/util/Utils";
import {Increment} from "platform/util/Increment";

export class IdGenerator {

    private readonly _prefix: string;
    private _increment: Increment;

    private constructor(prefix?: string) {
        if (Utils.isNotEmpty(prefix)) {
            this._prefix = prefix;
            this._increment = Increment.from(0);
        }
    }

    public static Of(prefix?: string): IdGenerator {
        return new IdGenerator(prefix);
    }

    public generate(): string {
        if (Utils.isNotEmpty(this._prefix)) {
            return this._prefix + "_" + this._increment.next();
        }
        return Math.random().toString(36).substr(2, 9);
    }
}
