enum Environment {

    DEV2 = "dev2",
    QA = "qa",
    QA2 = "qa2",
    ST = "st",
    LT = "lt",
    QA_PROD = "qaprod",
    PROD = "prod",
}

namespace Environment {

    let ENV: Environment = process.env.ENVIRONMENT as Environment;

    export function SetEnvironment(env: Environment): void {
        ENV = env;
    }

    export function environment(): Environment {
        return ENV;
    }

    export function isDev(): boolean {
        return ENV === Environment.DEV2;
    }

    export function isQA(): boolean {
        return ENV === Environment.QA || ENV === Environment.QA2;
    }

    export function isProd(): boolean {
        return ENV === Environment.PROD;
    }

    export function isStProd(): boolean {
        return ENV === Environment.ST || ENV === Environment.LT || ENV === Environment.PROD;
    }

    export function from(env: string): Environment {
        if (env) {
            switch (env) {
                case Environment.DEV2:
                    return Environment.DEV2;
                case Environment.QA2:
                    return Environment.QA2;
                case Environment.ST:
                    return Environment.ST;
                case Environment.LT:
                    return Environment.LT;
                case Environment.QA_PROD:
                    return Environment.QA_PROD;
                case Environment.PROD:
                    return Environment.PROD;
            }
        }
        return null;
    }

    export function text(env: Environment): string {
        if (env) {
            switch (env) {
                case Environment.DEV2:
                    return "DEV2";
                case Environment.QA:
                    return "QA";
                case Environment.QA2:
                    return "QA2";
                case Environment.ST:
                    return "Staging";
                case Environment.LT:
                    return "Load Test";
                case Environment.QA_PROD:
                    return "QA Prod";
                case Environment.PROD:
                    return "PROD";
            }
        }
        return null;
    }
}

export {
    Environment
};
