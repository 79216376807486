import {HttpHeader} from "platform/network/http/Http";

class XhrHeaders {

    private static _browserName: string;
    private static _browserVersion: string;

    public static set browserName(value: string) {
        this._browserName = value;
    }

    public static set browserVersion(value: string) {
        this._browserVersion = value;
    }

    public static Value(token?: string, headers?: HttpHeader[]): HttpHeader[] {
        const requestHeaders: HttpHeader[] = headers || [
            {name: "Content-Type", value: "application/json"},
        ];
        if (XhrHeaders._browserName) {
            requestHeaders.push({name: "browser-name", value: XhrHeaders._browserName});
        }
        if (XhrHeaders._browserVersion) {
            requestHeaders.push({name: "browser-version", value: XhrHeaders._browserVersion});
        }
        if (token) {
            requestHeaders.push({name: "ctoken", value: "sc|" + token});
        }
        return requestHeaders;
    }
}

export {
    XhrHeaders
};
