import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {DepositReduxState} from "core/redux/deposit/DepositReduxState";
import DepositEngine from "core/engine/DepositEngine";
import {
    DoSubmitDepositCCType,
    SetCreditCardProvider,
    SetCreditCardProviderPayload,
    SetCreditCardValid,
    SetCreditCardValidPayload,
    SetDepositAmount,
    SetDepositConfiguration,
    SetDepositConfigurationPayload,
    SetPaymentMethod,
    SetPaymentMethodPayload,
    SetDepositCurrency,
    CurrencyPayload,
    SelectBankCurrency,
    SelectBankCurrencyPayload,
    SelectBank,
    SelectBankPayload,
    TryChangeCurrencyType,
    SetReceiptDetails,
    SetReceiptDetailsPayload,
    DoSubmitDepositAPMType,
    SetDepositAmountError,
    SetDepositAmountErrorPayload,
    ValidateDepositAmountType,
    MergeDepositFormPayload,
    MergeClientDetails,
    DoSubmitDepositCC,
    DoSubmitDepositCCPayload,
    SetSelectedPaymentProvider,
    SetSelectedPaymentProviderPayload,
    SetBriteAccessToken,
    DoVerifyDepositAPMType,
    UpdateDepositConfigurationType,
    UpdateDepositConfiguration, UpdateDepositConfigurationPayload
} from "core/redux/deposit/DepositReduxActions";
import {PaymentMethod} from "enum/PaymentMethod";
import Utils from "platform/util/Utils";
import {Currency} from "platform/enum/Currency";
import {TSMap} from "typescript-map";
import {SetAppReadyType} from "platform/redux/core/CoreActions";
import {
    BankDetail,
    ClientDetail,
    DepositCurrency,
    PaymentProvider
} from "protocol/response/GetDepositConfigurationsResponse";
import {NumberPayload, StringPayload} from "core/redux/StoreActions";
import {FieldType} from "enum/FieldType";
import {DepositUtil} from "core/util/DepositUtil";

export const DEF_CREDIT_CARD_LENGTH: number = 20;

export default class DepositReducer extends Reducer<DepositReduxState> {

    private static _instance: DepositReducer;

    public static instance(): DepositReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const depositEngine: DepositEngine = Platform.engine(ServiceType.Deposit);
        this._middlewareActions.set(SetAppReadyType, depositEngine.onAppReady);
        this._middlewareActions.set(TryChangeCurrencyType, depositEngine.onTryChangeCurrency);
        this._middlewareActions.set(DoSubmitDepositCCType, depositEngine.doSubmitDepositCC);
        this._middlewareActions.set(DoSubmitDepositAPMType, depositEngine.doSubmitDepositAPM);
        this._middlewareActions.set(ValidateDepositAmountType, depositEngine.doValidatePaymentAmount);
        this._middlewareActions.set(DoVerifyDepositAPMType, depositEngine.doVerifyDepositAPM);
    }

    public get name(): string {
        return "deposit";
    }

    protected setup(builder: ReducerBuilder<DepositReduxState>): void {
        builder
            .init({
                Currencies: new TSMap<Currency, DepositCurrency>(),
                PaymentMethod: PaymentMethod.CreditCard,
                PaymentMethods: [PaymentMethod.CreditCard],
                PaymentProviders: new TSMap<number, PaymentProvider>(),
                PaymentProviderGroups: new TSMap<string, PaymentProvider[]>(),
                PaymentProviderIdPerEWalletId: new TSMap<string, number>(),
                SelectedPaymentProvider: new TSMap<string | number, PaymentProvider>(),
                supportedCreditCards: [],
                Form: {},
                maxCardLength: DEF_CREDIT_CARD_LENGTH,
                Client: {},
                BanksCurrencies: [],
                Banks: new TSMap<Currency, BankDetail[]>(),
                BankSelection: new TSMap<Currency, BankDetail>()
            })
            .handle(SetDepositConfiguration, (state: DepositReduxState, {payload}: Action<SetDepositConfigurationPayload>) => {
                this._logger.debug("Set deposit configuration.");
                const {
                    CreditCards,
                    Currencies,
                    PaymentMethods,
                    PaymentProviders,
                    PaymentProviderGroups,
                    PaymentProviderIdPerEWalletId,
                    BanksCurrencies,
                    Banks,
                    BankSelection,
                    Client,
                    PlatformUrl,
                    ChargeInDifferentCurrencyDisclaimer
                } = payload;
                return Object.assign({}, state, {
                    PaymentMethod: PaymentMethods[0],
                    PaymentMethods,
                    PaymentProviders,
                    PaymentProviderGroups,
                    PaymentProviderIdPerEWalletId,
                    Currencies,
                    supportedCreditCards: CreditCards,
                    Form: {
                        ...state.Form,
                        cardName: `${Utils.nullToEmpty(Client?.FirstName)} ${Utils.nullToEmpty(Client?.LastName)}`
                    },
                    Client: payload.Client,
                    BanksCurrencies,
                    Banks,
                    BankSelection,
                    BankCurrency: BanksCurrencies[0],
                    PlatformUrl,
                    ChargeInDifferentCurrencyDisclaimer
                });
            })
            .handle(UpdateDepositConfiguration, (state: DepositReduxState, {payload}: Action<UpdateDepositConfigurationPayload>) => {
                const {Currencies,} = payload;
                return Object.assign({}, state, {
                    Currencies
                });
            })
            .handle(SetDepositAmount, (state: DepositReduxState, {payload}: Action<NumberPayload>) => {
                this._logger.debug("Set deposit Amount: " + payload.value);
                return Object.assign({}, state, {
                    Amount: payload.value
                });
            })
            .handle(SetDepositAmountError, (state: DepositReduxState, {payload}: Action<SetDepositAmountErrorPayload>) => {
                return Object.assign({}, state, {
                    AmountError: payload.error
                });
            })
            .handle(SetDepositCurrency, (state: DepositReduxState, {payload}: Action<CurrencyPayload>) => {
                this._logger.debug("Set selected currency: " + payload.currency);
                return Object.assign({}, state, {
                    SelectedCurrency: payload.currency
                });
            })
            .handle(SetSelectedPaymentProvider, (state: DepositReduxState, {payload}: Action<SetSelectedPaymentProviderPayload>) => {
                const newState: DepositReduxState = Utils.merge({}, state);
                newState.SelectedPaymentProvider.set(payload.paymentMethod, payload.provider);
                if (!DepositUtil.isBrite(newState.PaymentProviders.get(payload.paymentMethod))) {
                    newState.BriteAccessToken = null;
                }
                return newState;
            })
            .handle(SetPaymentMethod, (state: DepositReduxState, {payload}: Action<SetPaymentMethodPayload>) => {
                this._logger.debug("Set payment method: " + payload.paymentMethod);
                const newState: DepositReduxState = Object.assign({}, state, {
                    PaymentMethod: payload.paymentMethod
                });
                if (!DepositUtil.isBrite(newState.PaymentProviders.get(payload.paymentMethod))) {
                    newState.BriteAccessToken = null;
                }
                return newState;
            })
            .handle(SetCreditCardProvider, (state: DepositReduxState, {payload}: Action<SetCreditCardProviderPayload>) => {
                this._logger.debug("Set credit card provider: " + payload.creditCardType + " Max card length: " + payload.maxCardLength);
                return Object.assign({}, state, {
                    creditCardType: payload.creditCardType,
                    maxCardLength: payload.maxCardLength || DEF_CREDIT_CARD_LENGTH
                });
            })
            .handle(DoSubmitDepositCC, (state: DepositReduxState, {payload}: Action<DoSubmitDepositCCPayload>) => {
                const Form: {[key: string]: string} = Utils.merge({}, state.Form);
                Form[FieldType.CardName] = payload.form[FieldType.CardName];
                return Object.assign({}, state, {
                    Form
                });
            })
            .handle(MergeClientDetails, (state: DepositReduxState, {payload}: Action<MergeDepositFormPayload>) => {
                const Client: ClientDetail = {...state.Client};
                Client.Address = payload.form[FieldType.Address];
                Client.City = payload.form[FieldType.City];
                Client.ZipCode = payload.form[FieldType.ZipCode];
                return Object.assign({}, state, {
                    Client
                });
            })
            .handle(SetCreditCardValid, (state: DepositReduxState, {payload}: Action<SetCreditCardValidPayload>) => {
                return Object.assign({}, state, {
                    creditCardValid: payload.valid
                });
            })
            .handle(SelectBankCurrency, (state: DepositReduxState, {payload}: Action<SelectBankCurrencyPayload>) => {
                return Object.assign({}, state, {
                    BankCurrency: payload.currency
                });
            })
            .handle(SelectBank, (state: DepositReduxState, {payload}: Action<SelectBankPayload>) => {
                const newState: DepositReduxState = Utils.merge({}, state);
                newState.BankSelection.set(payload.bank.AssetName, payload.bank);
                return newState;
            })
            .handle(SetReceiptDetails, (state: DepositReduxState, {payload}: Action<SetReceiptDetailsPayload>) => {
                return Object.assign({}, state, {
                    CreditCardReceiptDetails: payload.CreditCardReceiptDetails,
                    APMReceiptDetails: payload.APMReceiptDetails
                });
            })
            .handle(SetBriteAccessToken, (state: DepositReduxState, {payload}: Action<StringPayload>) => {
                return Object.assign({}, state, {
                    BriteAccessToken: payload.value
                });
            });
    }
}
