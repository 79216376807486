/* tslint:disable:no-console */
import Utils from "platform/util/Utils";
import {IntegrationLog} from "platform/integration/message/IntegrationLog";
import WebUtil from "platform/util/WebUtil";
import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";

enum LogLevel {

    DISABLED = "disabled",
    ERROR = "error",
    WARNING = "warn",
    INFO = "info",
    DEBUG = "debug",
}

const LogLevelPriority = {};
LogLevelPriority[LogLevel.DEBUG] = 0;
LogLevelPriority[LogLevel.INFO] = 1;
LogLevelPriority[LogLevel.WARNING] = 2;
LogLevelPriority[LogLevel.ERROR] = 3;
LogLevelPriority[LogLevel.DISABLED] = 4;

namespace LogLevel {
    export function deserialize(logLevel: string): LogLevel {
        if (Utils.isNotEmpty(logLevel)) {
            switch (logLevel) {
                case "disabled":
                    return LogLevel.DISABLED;
                case "error":
                    return LogLevel.ERROR;
                case "warn":
                    return LogLevel.WARNING;
                case "info":
                    return LogLevel.INFO;
                case "debug":
                    return LogLevel.DEBUG;
            }
        }
        return null;
    }
}

const PostMessageLog: boolean = WebUtil.urlParam("pmLog") === "true";
const UID: string = WebUtil.urlParam("uid");

class Logger {

    private static _LOG_LEVEL: LogLevel = LogLevel.DEBUG;
    private static _allLogs: string[] = [];
    private name: string;

    private constructor(name: string) {
        this.name = name;
    }

    public static Of(name: string): Logger {
        return new Logger(name);
    }

    public static AllLogs(): string[] {
        return this._allLogs;
    }

    public static setLogLevel(logLevel: string): void {
        Logger.SetLogLevel(LogLevel.deserialize(logLevel));
    }

    public static SetLogLevel(logLevel: LogLevel): void {
        if (Utils.isNotNull(logLevel)) {
            Logger._LOG_LEVEL = logLevel;
        }
    }

    public static getLogLevel(): LogLevel {
        return this._LOG_LEVEL;
    }

    public debug(...data: any[]): void {
        this.log(LogLevel.DEBUG, " D ", data);
    }

    public info(...data: any[]): void {
        this.log(LogLevel.INFO, " I ", data);
    }

    public warn(...data: any[]): void {
        this.log(LogLevel.WARNING, " W ", data);
    }

    public error(...data: any[]): void {
        this.log(LogLevel.ERROR, " E ", data);
    }

    private log(level: LogLevel, sLevel: string, data: any[]): void {
        if (LogLevelPriority[level] >= LogLevelPriority[Logger._LOG_LEVEL]) {
            // eslint-disable-next-line no-console
            console[level](...[this.format(sLevel), ...data]);
        }
        const sLog: string = this.format(sLevel) + data?.join(" ");
        Logger._allLogs.push(sLog);
        if (PostMessageLog && (window as any).ReactNativeWebView) {
            const log: IntegrationMessage = new IntegrationLog(sLog);
            log.uid = UID;
            (window as any).ReactNativeWebView.postMessage(JSON.stringify(log));
        }
    }

    private format(level: string): string {
        return [Logger.formatDate(), level, "[", this.name, "]", " "].join("");
    }

    private static formatDate(): string {
        const date: Date = new Date();
        return [Utils.pad(date.getDate()), Utils.pad(date.getMonth() + 1), date.getFullYear()].join("-") + " "
            + [Utils.pad(date.getHours()), Utils.pad(date.getMinutes()), Utils.pad(date.getSeconds())].join(":") + "." + date.getMilliseconds();
    }
}

export {LogLevel, Logger};
