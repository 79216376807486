import {AbstractIntegration} from "platform/integration/AbstractIntegration";
import {IntegrationWinInit, WinInitMeta} from "platform/integration/message/handshake/IntegrationWinInit";
import WebUtil from "platform/util/WebUtil";
import Utils from "platform/util/Utils";
import {IntegrationPageReady} from "platform/integration/message/IntegrationPageReady";
import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";
import {IntegrationWinInitialized} from "platform/integration/message/handshake/IntegrationWinInitialized";
import {PostMessageConnectionProvider} from "platform/integration/connection/PostMessageConnectionProvider";

export class RemoteWin extends AbstractIntegration {

    private static _instance: RemoteWin;
    private readonly _onInit: (meta: WinInitMeta, done: () => void) => void;
    private _handler: any;

    private constructor(onInit?: (meta: WinInitMeta, done: () => void) => void, uid?: string) {
        super(PostMessageConnectionProvider.instance(), WebUtil.parentWindow(), uid ? uid : WebUtil.urlParam("uid"));
        this._onInit = onInit;
    }

    public static instance(onInit?: (meta: WinInitMeta, done: () => void) => void, uid?: string): RemoteWin {
        return this._instance || (this._instance = new RemoteWin(onInit, uid));
    }

    public onConnected(): void {
        super.onConnected();
        this._handler = setInterval(this.pushReady, 200);
        this.pushReady();
    }

    private pushReady = () => {
        this._logger.debug("Sending page ready: " + this.uId());
        this.sendMessage(new IntegrationPageReady());
    }

    public onMessage(message: IntegrationMessage): void {
        const classId: number = message.classId;
        if (Utils.isNotNull(classId) && this.uId() === message.uid) {
            switch (classId) {
                case IntegrationClassId.WIN_INIT:
                    if (Utils.isNotNull(this._handler)) {
                        this._logger.debug("Init win: " + message.uid);
                        clearInterval(this._handler);
                        this._handler = null;
                        const done: () => void = () => {
                            this.sendMessage(new IntegrationWinInitialized());
                        };
                        if (Utils.isNotNull(this._onInit)) {
                            this._onInit((message as IntegrationWinInit).meta, done);
                        } else {
                            done();
                        }
                    }
                    break;
                default:
                    super.consume(message);
                    break;
            }
        }
    }
}
