import { TSMap } from "typescript-map";
import ConnectionListenerAdapter from "platform/listener/ConnectionListenerAdapter";
import {Logger} from "platform/logger/Logger";
import {IMessageHandler} from "platform/handler/MessageHandler";
import {Win} from "platform/integration/win/Win";
import Utils from "platform/util/Utils";
import {HandlerRegistration} from "platform/handler/HandlerRegistration";
import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationConnection} from "platform/integration/connection/IntegrationConnection";
import {IntegrationBridgeType} from "platform/integration/bridge/IntegrationBridgeType";
import {IdGenerator} from "platform/util/IdGenerator";
import {ConnectionListener} from "platform/listener/ConnectionListener";

export interface ConnectionProvider {

    createConnection(win: Win, listener: ConnectionListener<IntegrationMessage>): IntegrationConnection<IntegrationMessage>;
}

export abstract class AbstractIntegration extends ConnectionListenerAdapter<IntegrationMessage> {

    protected readonly _logger: Logger = Logger.Of(this.constructor.toString().match(/\w+/g)[1]);
    private readonly _handlers: TSMap<number, IMessageHandler<IntegrationMessage>> = new TSMap<number, IMessageHandler<IntegrationMessage>>();
    private readonly _idGenerator: IdGenerator = IdGenerator.Of();
    private readonly _connection: IntegrationConnection<IntegrationMessage>;
    private readonly _win: Win;
    private readonly _uid: string;

    protected constructor(provider: ConnectionProvider, win: Win, uid?: string) {
        super();
        this._win = win;
        this._uid = Utils.isNotEmpty(uid) ? uid : this._idGenerator.generate();
        this._connection = provider.createConnection(win, this);
    }

    public connect(bridgeType?: IntegrationBridgeType): void {
        this._connection.connect(bridgeType);
    }

    public disconnect(): void {
        this._connection.disconnect();
    }

    public onConnected(): void {
        this._logger.debug("On connected");
    }

    public onDisconnected(): void {
        this._logger.debug("On disconnected");
    }

    public uId(): string {
        return this._uid;
    }

    protected win(): Win {
        return this._win;
    }

    public close(): void {
        this.disconnect();
        if (Utils.isNotNull(this._win)) {
            this._win.close();
        }
    }

    public type(): IntegrationBridgeType {
        return this._connection.type();
    }

    public sendMessage(message: IntegrationMessage): void {
        if (Utils.isNotNull(message)) {
            message.uid = this._uid;
            this._connection.sendMessage(message);
        }
    }

    public setHandler(handler: IMessageHandler<IntegrationMessage>): HandlerRegistration {
        if (Utils.isNotNull(handler)) {
            if (this._handlers.has(handler.classId())) {
                this._logger.warn("Handler for " + handler.classId() + " already registered");
            } else {
                const self = this;
                this._handlers.set(handler.classId(), handler);
                return {
                    unregister() {
                        self._handlers.delete(handler.classId());
                    }
                };
            }
        }
        return null;
    }

    protected handler(classId: number): IMessageHandler<IntegrationMessage> {
        return this._handlers.get(classId);
    }

    protected consume(message: IntegrationMessage): void {
        if (Utils.isNotNull(message)) {
            const handler: IMessageHandler<IntegrationMessage> = this.handler(message.classId);
            if (Utils.isNotNull(handler)) {
                handler.handle(message);
            }
        }
    }
}
