enum ModalType {

    DepositLimits = "depositLimits",
    PaymentProcessing = "paymentProcessing",
    PaymentPending = "paymentPending",
}

export {
    ModalType
};
