import Utils from "platform/util/Utils";
import {TSMap} from "typescript-map";
import {HandlerRegistration} from "platform/handler/HandlerRegistration";
import {Navigator} from "platform/navigator/Navigator";

export class WebNavigator implements Navigator {

    private static _instance: WebNavigator;
    private readonly _initialParameters: TSMap<string, string> = new TSMap<string, string>();
    private readonly _whenHashChanged: ((oldURL: string, newURL: string, hash: string) => void)[] = [];
    private readonly _whenPopState: ((state: any) => void)[] = [];
    private _initialPath: string;

    private constructor() {
        window.onhashchange = (e: HashChangeEvent) => {
            if (Utils.isArrayNotEmpty(this._whenHashChanged)) {
                this._whenHashChanged.forEach((v: (oldURL: string, newURL: string, hash: string) => void) => {
                    v(e.oldURL, e.newURL, location.hash);
                });
            }
        };
        window.onpopstate = (e: PopStateEvent) => {
            if (Utils.isArrayNotEmpty(this._whenPopState)) {
                this._whenPopState.forEach((v: (state: any) => void) => {
                    v(e.state);
                });
            }
        };
    }

    public whenHashChanged(v: (oldURL: string, newURL: string, hash: string) => void): HandlerRegistration {
        if (Utils.isNotNull(v)) {
            const self = this;
            this._whenHashChanged.push(v);
            return {
                unregister() {
                    self._whenHashChanged.splice(self._whenHashChanged.indexOf(v), 1);
                }
            };
        }
        return null;
    }

    public whenPopState(v: (state: any) => void): HandlerRegistration {
        if (Utils.isNotNull(v)) {
            const self = this;
            this._whenPopState.push(v);
            return {
                unregister() {
                    self._whenPopState.splice(self._whenPopState.indexOf(v), 1);
                }
            };
        }
        return null;
    }

    public init(): void {
        this._initialPath = window.location.pathname;
        const initialHash: string = window.location.hash;
        const value: string = initialHash || this._initialPath;
        if (Utils.isNotEmpty(value)) {
            const params: string[] = value.split("@");
            if (Utils.isArrayNotEmpty(params)) {
                params.forEach((parameter: string) => {
                    const kv: string[] = parameter.split("=");
                    if (kv.length === 2 && Utils.isNotEmpty(kv[0]) && Utils.isNotEmpty(kv[1])) {
                        const k: string = kv[0].trim();
                        const v: string = kv[1].trim();
                        this._initialParameters.set(k, v);
                    }
                });
            }
        }
        this.removeHash();
    }

    public initialPath(): string {
        return this._initialPath;
    }

    public initialParameter(key: string): string {
        return this._initialParameters.get(key);
    }

    public setHash(hash: string): void {
        window.location.hash = hash;
    }

    public removeHash(): void {
        if (Utils.isNotNull(location.hash)) {
            history.replaceState({}, document.title, location.href.substr(0, location.href.length - location.hash.length));
        }
    }

    public removeDpk(): void {
        const parts: string[] = location.href.split("?");
        if (Utils.isNotEmpty(parts[1])) {
            const queryParameters: string[] = parts[1].split(parts[1].indexOf("&amp;") > 0 ? "&amp;" : "&");
            let result: string = parts[0];
            let firstParameter: boolean = true;
            queryParameters.forEach((queryParameter: string) => {
                if (queryParameter.indexOf("dpk=") !== 0) {
                    result += (firstParameter ? "?" : "&") + queryParameter;
                    firstParameter = false;
                }
            });
            history.replaceState({}, document.title, result);
        }
    }

    public static instance(): WebNavigator {
        return this._instance || (this._instance = new this());
    }
}
