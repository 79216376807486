import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";

export class IntegrationSetInMemoryValue implements IntegrationMessage {

    public classId: number = IntegrationClassId.SET_IN_MEMORY_VALUE;
    public key: string;
    public value: string;

    public constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}
