import {Environment} from "platform/environment/Environment";
import {Logger} from "platform/logger/Logger";
import {Win} from "platform/integration/win/Win";
import {ConnectionListener} from "platform/listener/ConnectionListener";
import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationConnection} from "platform/integration/connection/IntegrationConnection";
import {Storage} from "platform/storage/Storage";
import {Navigator} from "platform/navigator/Navigator";
import {Audio} from "platform/audio/Audio";
import {EventType} from "platform/enum/EventType";
import {EnvType} from "platform/enum/EnvType";
import {BITracker} from "platform/analytics/BITracker";

export abstract class BaseEnvironment implements Environment {

    protected _logger: Logger = Logger.Of(this.constructor.toString().match(/\w+/g)[1]);

    protected constructor() {}

    public abstract type(): EnvType;

    public abstract storage(): Storage;

    public abstract sessionStorage(): Storage;

    public abstract createConnection(win: Win, listener: ConnectionListener<IntegrationMessage>): IntegrationConnection<IntegrationMessage>;

    public abstract openWindow(url: string, params?: string);

    public abstract closeSelf(): void;

    public abstract redirect(url: string): void;

    public abstract navigator(): Navigator;

    public abstract audio(): Audio;

    public abstract bi(): BITracker;

    public abstract addEventListener(type: EventType, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;

    public abstract removeEventListener(type: EventType, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
}
