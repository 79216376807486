import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";

export class IntegrationOpenExternalUrl implements IntegrationMessage {

    public classId: number = IntegrationClassId.OPEN_EXTERNAL_URL;
    public url: string;

    constructor(url: string) {
        this.url = url;
    }
}
