export enum TranslationKey {

    UIErrorMessage = "UIErrorMessage",
    accountNumber = "accountNumber",
    address = "address",
    amount = "amount",
    apply = "apply",
    back = "back",
    backToPlatform = "backToPlatform",
    bankAddress = "bankAddress",
    bankTransfer = "bankTransfer",
    beneficiaryName = "beneficiaryName",
    callMe = "callMe",
    cancel = "cancel",
    cardHolderName = "cardHolderName",
    chargedAmount = "chargedAmount",
    choosePreferredBankCurrency = "choosePreferredBankCurrency",
    chooseYourPaymentMethod = "chooseYourPaymentMethod",
    city = "city",
    continueWith = "continueWith",
    continue_ = "continue_",
    copiedToClipboard = "copiedToClipboard",
    country = "country",
    creditCard = "creditCard",
    creditCardNumber = "creditCardNumber",
    creditCardType = "creditCardType",
    currency = "currency",
    cvvInfo = "cvvInfo",
    date = "date",
    debitCard = "debitCard",
    deposit = "deposit",
    depositAmount = "depositAmount",
    depositMaxAmount = "depositMaxAmount",
    depositMinAmount = "depositMinAmount",
    depositPageSubtitleDepositSuccessful = "deposit.page.subtitle.DepositSuccessful",
    depositPageSubtitleDepositTopUp = "deposit.page.subtitle.DepositTopUp",
    depositPageTitleDepositSuccessful = "deposit.page.title.DepositSuccessful",
    depositPageTitleDepositTopUp = "deposit.page.title.DepositTopUp",
    documentTitleDepositSuccessful = "document.title.DepositSuccessful",
    documentTitleDepositTopUp = "document.title.DepositTopUp",
    errorAddress = "error.address",
    errorAmount = "error.amount",
    errorAmountLarge = "error.amount.large",
    errorCardCvv = "error.cardCvv",
    errorCardCvvLength = "error.cardCvv.length",
    errorCardExpiration = "error.cardExpiration",
    errorCardExpirationExpired = "error.cardExpirationExpired",
    errorCardName = "error.cardName",
    errorCardNameRegexp = "error.cardName.regexp",
    errorCardNumber = "error.cardNumber",
    errorCardNumberProvider = "error.cardNumber.provider",
    errorCardNumberWrong = "error.cardNumber.wrong",
    errorCity = "error.city",
    errorCountry = "error.country",
    errorDepositLimits = "error.depositLimits",
    errorIdCard = "error.idCard",
    errorInvalid = "error.invalid",
    errorIsRequired = "error.isRequired",
    errorZipCode = "error.zipCode",
    expirationDate = "expirationDate",
    formFieldCardCvv = "form.field.cardCvv",
    formFieldCardName = "form.field.cardName",
    formFieldCardNumber = "form.field.cardNumber",
    formFieldIdCard = "form.field.idCard",
    formFieldMonth = "form.field.month",
    formFieldPromotionCode = "form.field.promotionCode",
    formFieldYear = "form.field.year",
    fullName = "fullName",
    guaranteedSafe = "guaranteedSafe",
    iban = "iban",
    merchantAccountEmail = "merchantAccountEmail",
    minimum = "minimum",
    mm = "mm",
    mmYY = "mmYY",
    modalDontCloseWindow = "modal.dontCloseWindow",
    modalPendingMessages = "modal.pendingMessages",
    no = "no",
    ok = "ok",
    otherPaymentMethods = "otherPaymentMethods",
    pay = "pay",
    payByCard = "payByCard",
    paymentProcessing = "paymentProcessing",
    ppGroupCash = "pp.group.Cash",
    ppGroupCreditCard = "pp.group.CreditCard",
    ppGroupCrypto = "pp.group.Crypto",
    ppGroupEWallet = "pp.group.EWallet",
    receiptNumber = "receiptNumber",
    redirectToAPM = "redirectToAPM",
    required = "required",
    secureDeposit = "secureDeposit",
    serverErrorGeneral = "server.error.general",
    showLess = "showLess",
    showMore = "showMore",
    stillPending = "stillPending",
    swift = "swift",
    topUp = "topUp",
    topUpAgain = "topUpAgain",
    tradingPlatformEmail = "tradingPlatformEmail",
    transactionId = "transactionId",
    willDoLater = "willDoLater",
    yes = "yes",
    yy = "yy",
    zipCode = "zipCode",
}
