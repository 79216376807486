import {ActionCreatorDefinition, createAction} from "redux-ts";
import {Currency} from "platform/enum/Currency";
import {
    BankDetail, ClientDetail,
    DepositCreditCard,
    DepositCurrency,
    PaymentProvider
} from "protocol/response/GetDepositConfigurationsResponse";
import {TSMap} from "typescript-map";
import {NumberPayload, StringPayload} from "core/redux/StoreActions";
import {PaymentAmountError} from "protocol/response/ValidateDepositAmountResponse";
import {DepositByCreditCardReceiptDetails} from "protocol/request/DepositByCreditCardResponse";
import {DepositVerifyAPMResponse} from "protocol/request/DepositVerifyAPMResponse";

export const SetDepositConfigurationType: string = "SetDepositConfiguration";
export const UpdateDepositConfigurationType: string = "UpdateDepositConfiguration";
export const SetDepositAmountType: string = "SetDepositAmount";
export const ValidateDepositAmountType: string = "ValidateDepositAmount";
export const SetDepositAmountErrorType: string = "SetDepositAmountError";
export const TryChangeCurrencyType: string = "TryChangeCurrency";
export const SetDepositCurrencyType: string = "SetDepositCurrency";
export const SetSelectedPaymentProviderType: string = "SetSelectedPaymentProvider";
export const SetPaymentMethodType: string = "SetPaymentMethod";
export const SetCreditCardProviderType: string = "SetCreditCardProvider";
export const MergeClientDetailsType: string = "MergeClientDetails";
export const SetCreditCardValidType: string = "SetCreditCardValid";
export const SelectBankCurrencyType: string = "SelectBankCurrency";
export const SelectBankType: string = "SelectBank";
export const DoSubmitDepositCCType: string = "DoSubmitDepositCC";
export const DoSubmitDepositAPMType: string = "DoSubmitDepositAPM";
export const DoVerifyDepositAPMType: string = "DoVerifyDepositAPM";
export const SetReceiptDetailsType: string = "SetReceiptDetails";
export const SetBriteAccessTokenType: string = "SetBriteAccessToken";

export interface SetDepositConfigurationPayload {

    Currencies: TSMap<Currency, DepositCurrency>;
    CreditCards: DepositCreditCard[];
    PaymentProviders: TSMap<string | number, PaymentProvider>;
    PaymentProviderGroups: TSMap<string, PaymentProvider[]>;
    PaymentProviderIdPerEWalletId: TSMap<string | number, number>;
    PaymentMethods: Array<string | number>;
    PlatformUrl: string;
    BanksCurrencies: Currency[];
    Banks: TSMap<Currency, BankDetail[]>;
    BankSelection: TSMap<Currency, BankDetail>;
    Client: ClientDetail;
    ChargeInDifferentCurrencyDisclaimer: string;
}

export interface UpdateDepositConfigurationPayload {

    Currencies: TSMap<Currency, DepositCurrency>;
}

export interface CurrencyPayload {

    currency: Currency;
}

export interface SetDepositAmountErrorPayload {

    error: PaymentAmountError;
}

export interface SetSelectedPaymentProviderPayload {

    paymentMethod: string | number;
    provider: PaymentProvider;
}

export interface SetPaymentMethodPayload {

    paymentMethod: string | number;
}

export interface SetCreditCardProviderPayload {

    creditCardType: string;
    maxCardLength: number;
}

export interface SetCreditCardValidPayload {

    valid: boolean;
}

export interface MergeDepositFormPayload {

    form: {[key: string]: string};
}

export interface SelectBankCurrencyPayload {

    currency: Currency;
}

export interface SelectBankPayload {

    bank: BankDetail;
}

export interface DoSubmitDepositCCPayload {

    form: {[key: string]: string};
}

export interface DoSubmitDepositAPMPayload {

    form: {[key: string]: string};
    token?: object;
    completion?: (success: boolean) => void;
}

export interface SetReceiptDetailsPayload {

    CreditCardReceiptDetails?: DepositByCreditCardReceiptDetails;
    APMReceiptDetails?: DepositVerifyAPMResponse;
}

export const SetDepositConfiguration: ActionCreatorDefinition<SetDepositConfigurationPayload, any> = createAction<SetDepositConfigurationPayload>(SetDepositConfigurationType);
export const UpdateDepositConfiguration: ActionCreatorDefinition<UpdateDepositConfigurationPayload, any> = createAction<UpdateDepositConfigurationPayload>(UpdateDepositConfigurationType);
export const SetDepositAmount: ActionCreatorDefinition<NumberPayload, any> = createAction<NumberPayload>(SetDepositAmountType);
export const ValidateDepositAmount: ActionCreatorDefinition<any, any> = createAction<any>(ValidateDepositAmountType);
export const SetDepositAmountError: ActionCreatorDefinition<SetDepositAmountErrorPayload, any> = createAction<SetDepositAmountErrorPayload>(SetDepositAmountErrorType);
export const TryChangeCurrency: ActionCreatorDefinition<CurrencyPayload, any> = createAction<CurrencyPayload>(TryChangeCurrencyType);
export const SetDepositCurrency: ActionCreatorDefinition<CurrencyPayload, any> = createAction<CurrencyPayload>(SetDepositCurrencyType);
export const SetSelectedPaymentProvider: ActionCreatorDefinition<SetSelectedPaymentProviderPayload, any> = createAction<SetSelectedPaymentProviderPayload>(SetSelectedPaymentProviderType);
export const SetPaymentMethod: ActionCreatorDefinition<SetPaymentMethodPayload, any> = createAction<SetPaymentMethodPayload>(SetPaymentMethodType);
export const SetCreditCardProvider: ActionCreatorDefinition<SetCreditCardProviderPayload, any> = createAction<SetCreditCardProviderPayload>(SetCreditCardProviderType);
export const MergeClientDetails: ActionCreatorDefinition<MergeDepositFormPayload, any> = createAction<MergeDepositFormPayload>(MergeClientDetailsType);
export const SetCreditCardValid: ActionCreatorDefinition<SetCreditCardValidPayload, any> = createAction<SetCreditCardValidPayload>(SetCreditCardValidType);
export const SelectBankCurrency: ActionCreatorDefinition<SelectBankCurrencyPayload, any> = createAction<SelectBankCurrencyPayload>(SelectBankCurrencyType);
export const SelectBank: ActionCreatorDefinition<SelectBankPayload, any> = createAction<SelectBankPayload>(SelectBankType);
export const DoSubmitDepositCC: ActionCreatorDefinition<DoSubmitDepositCCPayload, any> = createAction<DoSubmitDepositCCPayload>(DoSubmitDepositCCType);
export const DoSubmitDepositAPM: ActionCreatorDefinition<DoSubmitDepositAPMPayload, any> = createAction<DoSubmitDepositAPMPayload>(DoSubmitDepositAPMType);
export const SetReceiptDetails: ActionCreatorDefinition<SetReceiptDetailsPayload, any> = createAction<SetReceiptDetailsPayload>(SetReceiptDetailsType);
export const SetBriteAccessToken: ActionCreatorDefinition<StringPayload, any> = createAction<StringPayload>(SetBriteAccessTokenType);
export const DoVerifyDepositAPM: ActionCreatorDefinition<StringPayload, any> = createAction<StringPayload>(DoVerifyDepositAPMType);
