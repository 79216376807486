enum FieldType {

    Amount = "amount",
    Currency = "currency",
    CardName = "cardName",
    CardNumber = "cardNumber",
    CardExpiration = "cardExpiration",
    CardCvv = "cardCvv",
    PromotionCode = "promotionCode",
    IDCard = "idCard",
    Address = "address",
    CountryCode = "countryCode",
    Country = "country",
    City = "city",
    ZipCode = "zipCode",
}

export {
    FieldType
};
