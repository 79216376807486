import {AbstractIntegration} from "platform/integration/AbstractIntegration";
import WebUtil from "platform/util/WebUtil";
import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import Utils from "platform/util/Utils";
import Platform from "platform/Platform";

export default class GlobalIntegration extends AbstractIntegration {

    private static _instance: GlobalIntegration;
    private _globalHandler: (message: IntegrationMessage) => void;
    private _messageQueue: IntegrationMessage[] = [];
    private _initialized: boolean;

    private constructor() {
        super(Platform.environment(),
            WebUtil.parentWindow() || WebUtil.window(), "GlobalIntegration");
        this.connect();
    }

    public static instance(): GlobalIntegration {
        return this._instance || (this._instance = new this());
    }

    public setInitialized(initialized: boolean): void {
        if (initialized && !this._initialized) {
            while (this._messageQueue.length > 0) {
                this.processMessage(this._messageQueue.shift());
            }
            this._initialized = true;
        }
    }

    public onMessage(message: IntegrationMessage): void {
        if (Utils.isNotNull(message)) {
            if (this._initialized) {
                this.processMessage(message);
            } else {
                this._logger.debug("Queuing message: " + message.classId);
                this._messageQueue.push(message);
            }
        }
    }

    public setGlobalHandler(globalHandler: (message: IntegrationMessage) => void): void {
        this._globalHandler = globalHandler;
    }

    private processMessage(message: IntegrationMessage) {
        const classId: number = message.classId;
        if (Utils.isNotNull(classId) && this.uId() === message.uid) {
            this.consume(message);
            if (Utils.isNotNull(this._globalHandler)) {
                this._logger.debug("Global handler received: ", message);
                this._globalHandler(message);
            }
        }
    }
}
