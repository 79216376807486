import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {TranslationReduxState} from "platform/redux/translation/TranslationReduxState";
import {
    LoadLanguagePayload, LoadLanguageType,
    SetDetectedLangCode, SetDetectedLangCodePayload,
    SetLangCode, SetLangCodePayload,
    SetSupportedLanguages, SetSupportedLanguagesPayload
} from "platform/redux/translation/TranslationActions";
import Utils from "platform/util/Utils";
import Platform from "platform/Platform";
import {LangCode} from "platform/enum/LangCode";
import {LSKey} from "platform/storage/Storage";

export default class TranslationReducer extends Reducer<TranslationReduxState> {

    private static _instance: TranslationReducer;

    private constructor() {
        super();
        this._middlewareActions.set(LoadLanguageType, this.loadLanguage);
    }

    public static instance(): TranslationReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "translation";
    }

    protected setup(builder: ReducerBuilder<TranslationReduxState>): void {
        builder
            .init({
                langCode: LangCode.NONE,
                supportedLanguages: Platform.config().supportedLanguages || []
            })
            .handle(SetDetectedLangCode, (state: TranslationReduxState, {payload}: Action<SetDetectedLangCodePayload>) => {
                this._logger.debug("Set detected lang code: " + payload.langCode);
                return Object.assign({}, state, {
                    detectedLangCode: payload.langCode
                });
            })
            .handle(SetLangCode, (state: TranslationReduxState, {payload}: Action<SetLangCodePayload>) => {
                this._logger.debug("Set lang code: " + payload.langCode);
                return Object.assign({}, state, {
                    langCode: payload.langCode
                });
            })
            .handle(SetSupportedLanguages, (state: TranslationReduxState, {payload}: Action<SetSupportedLanguagesPayload>) => {
                this._logger.debug("Set supported languages: " + payload.languages);
                return Object.assign({}, state, {
                    supportedLanguages: payload.languages
                });
            });
    }

    private loadLanguage = (payload: LoadLanguagePayload) => {
        if (Utils.isNotNull(payload.langCode)) {
            this._logger.info("Load language: " + payload.langCode);
            Platform.storage().setItem(LSKey.LangCode, payload.langCode);
            Platform.loadTranslation(payload.langCode)
                .then((answer: {langCode: LangCode, data: any}) => {
                    Platform.dispatch(SetLangCode({
                        langCode: answer.langCode,
                        data: answer.data
                    }));
                })
                .catch((error: any) => {
                    this._logger.warn("Can't load translations for lang code: " + payload.langCode + " Error: " + JSON.stringify(error));
                });
        } else {
            this._logger.warn("Load lang code not defined");
        }
    }
}
