import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";

export class IntegrationPageReady implements IntegrationMessage {

    public classId: number = IntegrationClassId.PAGE_READY;
    public pageType: string;

    public constructor(pageType?: string) {
        this.pageType = pageType;
    }
}
