import {Engine} from "platform/engine/Engine";
import Platform from "platform/Platform";
import Utils from "platform/util/Utils";
import CoreEngine from "platform/engine/CoreEngine";
import {LoadLanguagePayload, SetSupportedLanguages} from "platform/redux/translation/TranslationActions";
import {Configuration} from "core/configuration/Configuration";
import {InitChat, SetAppReadyPayload, SetBrandPropsPayload, SetUrls} from "platform/redux/core/CoreActions";
import {HttpReject} from "platform/network/http/Http";
import {
    AvailableLanguage,
    LoginConfigurationResponse,
    LoginDynamicConfiguration
} from "protocol/response/LoginConfigurationResponse";
import {LangCode} from "platform/enum/LangCode";
import {XhrManager} from "core/engine/XhrManager";
import {AppState} from "core/state/AppState";
import {ServiceType} from "enum/ServiceType";
import {LoginConfigurationRequest} from "protocol/request/LoginConfigurationRequest";
import WebUtil from "platform/util/WebUtil";
import {UrlType} from "platform/enum/UrlType";
import {Locale} from "core/format/Locale";
import {
    DoGetURLPayload,
    DoRegisterActivityPayload,
    SetBackNavigation,
    SetCountries,
    SetDynamicConfiguration,
    SetHideBackToPlatformButton,
    SetKycContext,
    SetWebViewPlatform,
} from "core/redux/app/AppReduxActions";
import {Bridge} from "core/integration/Bridge";
import {RNBridge} from "core/integration/RNBridge";
import {RegisterActivityRequest} from "protocol/request/RegisterActivityRequest";
import {GetUrlType} from "platform/protocol/enum/GetUrlType";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {GetURLByTypeRequest} from "platform/protocol/common/GetURLByTypeRequest";
import {GetURLByTypeResponse} from "platform/protocol/common/GetURLByTypeReponse";
import {StoreState} from "core/redux/StoreState";
import {
    UrlBackNavigation,
    UrlHidePlatformButton,
    UrlKycContext,
    UrlVerifyAPM,
    UrlVerifyCCGuid,
    UrlWebView
} from "core/util/UrlConstants";
import {ReadyState} from "core/state/ReadyState";
import GlobalIntegration from "platform/integration/GlobalIntegration";
import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";
import {IntegrationNavigateTo} from "platform/integration/message/IntegrationNavigateTo";
import {GetAllCountriesRequest} from "platform/protocol/common/GetAllCountriesRequest";
import {GetAllCountriesResponse} from "platform/protocol/common/GetAllCountriesResponse";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {TSMap} from "typescript-map";
import {WebViewPlatformType} from "enum/WebViewPlatformType";
import {GetAfterLoginConfigurationResponse} from "protocol/account/GetAfterLoginConfigurationResponse";
import {CustomerIOWeb} from "platform/analytics/CustomerIOWeb";
import {TokenManager} from "core/util/TokenManager";
import {DynamicConfiguration} from "platform/protocol/common/DynamicConfiguration";
import {ConfigUtil} from "core/util/ConfigUtil";

export default class AppEngine extends Engine {

    private static _instance: AppEngine;

    public static instance(): AppEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        CoreEngine.instance().setup().catch(() => {});
        await Locale.load();
        if (RNBridge.hasBridge()) {
            const token: string = await TokenManager.token();
            this._logger.debug(`App Token: ${token}`);
        }
        const {mixpanelApiKey, brand} = Platform.config();
        if (mixpanelApiKey) {
            Platform.bi().init(mixpanelApiKey, brand);
        }
        if (UrlWebView) {
            Platform.dispatch(SetWebViewPlatform({webViewPlatform: UrlWebView as WebViewPlatformType}));
        }
        if (UrlHidePlatformButton) {
            Platform.dispatch(SetHideBackToPlatformButton({}));
        }
        if (Utils.isEmpty(UrlVerifyCCGuid) && !UrlVerifyAPM) {
            Platform.dispatch(SetKycContext({value: UrlKycContext}));
            Platform.dispatch(SetBackNavigation({value: UrlBackNavigation}));
        }
    }

    public doSetBrandProps = ({}: SetBrandPropsPayload): void => {
        ReadyState.hasLDBrandProps = true;
    }

    public onAppReady = ({ready}: SetAppReadyPayload): void => {
        if (ready) {
            this._logger.debug(`On app ready. Kyc context: ${UrlKycContext}`);
            XhrManager.sendToLoginSecure({}, "GetAfterLoginConfiguration").then((response: GetAfterLoginConfigurationResponse) => {
                Platform.bi().identify({
                    UserId: response?.UserId,
                    theme: Platform.reduxState<StoreState>().core.theme,
                    realAccount: response?.IsRealAccount,
                    device: RNBridge.hasBridge() ? "Mobile" : WebUtil.isMobile() ? "Web Mobile" : "Web Desktop",
                });
                const {CustomerIoBrands, CustomerIoOrganizationId, CustomerIoSiteId} = Platform.reduxState<StoreState>().app.dynamicConfiguration;
                if (CustomerIoBrands?.indexOf(Platform.config().brandId) >= 0 && CustomerIoOrganizationId && CustomerIoSiteId) {
                    CustomerIOWeb.identify(CustomerIoOrganizationId, CustomerIoSiteId, {
                        id: response?.UserId,
                        email: response?.UserName
                    });
                }
            }).catch((e) => {
                this._logger.warn("Failed get after login configuration" + JSON.stringify(e));
            });
            if (UrlKycContext && WebUtil.isFrame()) {
                Bridge.Instance().hideLoader();
            }
            GlobalIntegration.instance().setGlobalHandler((message: IntegrationMessage) => {
                if (message.classId === IntegrationClassId.NAVIGATE_TO) {
                    const navTo: IntegrationNavigateTo = message as IntegrationNavigateTo;
                    if (navTo.url === "kyc:nextStep" && WebUtil.isFrame()) {
                        Bridge.Instance().close();
                    }
                }
            });
            const msg: string = JSON.stringify({operation: "appReady"});
            RNBridge.send(msg);
            if (WebUtil.parentWindow()) {
                WebUtil.parentWindow().postMessage(msg, "*");
            }
        }
    }

    public onLoadLanguage = async (payload: LoadLanguagePayload) => {
        const BrandId: number = Platform.config<Configuration>().brandId;
        this._logger.debug("Fetching config for lang: " + payload.langCode);
        const request: LoginConfigurationRequest = {BrandId, LanguageCode: payload.langCode};
        const answer: [HttpReject, LoginConfigurationResponse] = await Utils.to(XhrManager.sendToLogin(request, "LoginConfiguration"));
        if (answer[0]) {
            this._logger.debug("Failed fetch configuration: " + answer[0]);
        } else {
            const response: LoginConfigurationResponse = answer[1];
            if (response?.SuccessStatus) {
                this._logger.debug("Config received.");
                const loginConfiguration: LoginDynamicConfiguration = response.DynamicConfiguration || {};
                let CustomerIoBrands: number[] = [];
                if (loginConfiguration.CustomerIoBrands) {
                    try {
                        CustomerIoBrands = JSON.parse(loginConfiguration.CustomerIoBrands);
                    } catch (e) {}
                }
                const dynamicConfiguration: DynamicConfiguration = {
                    CustomerIoSiteId: loginConfiguration.CustomerIoSiteId,
                    CustomerIoApiKey: loginConfiguration.CustomerIoApiKey,
                    CustomerIoOrganizationId: loginConfiguration.CustomerIoOrganizationId,
                    CustomerIoRegion: loginConfiguration.CustomerIoRegion,
                    CustomerIoBrands,
                };
                Platform.dispatch(SetDynamicConfiguration({configuration: dynamicConfiguration}));
                Platform.dispatch(SetUrls({
                    urls: [
                        {type: UrlType.NewLogin, url: ConfigUtil.UseAddressBarDomain(decodeURIComponent(response.NewLoginUrl))}
                    ]
                }));
                const appState: AppState = Platform.state<AppState>(ServiceType.App)
                const languages: LangCode[] = [];
                response.AvailableLanguageConfigurations.forEach((langConfig: AvailableLanguage) => {
                    const langCode: LangCode = LangCode[langConfig.Code.toUpperCase()];
                    if (Utils.isNotNull(langCode) && langConfig.IsActive && Platform.config<Configuration>().supportedLanguages.indexOf(langCode) > -1) {
                        appState.addLanguage(langCode, langConfig);
                        languages.push(langCode);
                    }
                });
                Platform.dispatch(SetSupportedLanguages({languages}));
                if (response.ChatUrl && WebUtil.isUrl(response.ChatUrl) && !WebUtil.isFrame() && !WebUtil.inWebView()) {
                    Platform.dispatch(InitChat({scriptUrl: response.ChatUrl}));
                }
            } else {
                this._logger.debug("Failed fetch config: " + response?.LocalizedErrorMessage);
            }
        }
        this.fetchCountries(payload.langCode).catch(() => {});
    }

    private fetchCountries = async (langCode: LangCode) => {
        const request: GetAllCountriesRequest = {LanguageCode: langCode};
        const answer: [HttpReject, GetAllCountriesResponse] = await Utils.to(XhrManager.sendToConfig(request, "GetAllCountries"));
        if (answer[0]) {
            this._logger.debug("Failed fetch countries list. Status: " + answer[0].status);
        } else {
            const {Countries} = answer[1];
            if (Utils.isArrayNotEmpty(Countries)) {
                const countries: TSMap<string, CountryInfo> = new TSMap<string, CountryInfo>();
                Countries.forEach((country: CountryInfo) => {
                    countries.set(country.Code, country);
                });
                Platform.dispatch(SetCountries({countries}));
            }
        }
    }

    public closeMe = (): void => {
        this._logger.debug("Asking to cloe me");
        if (WebUtil.inIosWebView()) {
            (window as any).webkit.messageHandlers.jsHandle.postMessage("closeWebView");
        } else if (WebUtil.inAndroidWebView()) {
            (window as any).MobileJsInterface.closeWebView();
        } else if (WebUtil.parentWindow()) {
            WebUtil.parentWindow().postMessage(JSON.stringify({operation: "closeGeneralWindow"}), "*");
        }
    }

    public doRegisterActivity = async ({activity, comments}: DoRegisterActivityPayload) => {
        const request: RegisterActivityRequest = {
            ActivityType: activity,
            Details: comments
        };
        XhrManager.sendToCompliance(request, "RegisterActivity").catch(() => {
            this._logger.debug("Failed register activity: " + activity);
        });
    }

    public doGetUrl = async ({urlType}: DoGetURLPayload) => {
        const URLType: GetUrlType = UrlType.getUrlType(urlType);
        if (Utils.isNotNull(URLType)) {
            const langCode: LangCode = LanguageUtil.languageCode();
            const request: GetURLByTypeRequest = {LanguageCode: langCode, URLType};
            const answer: [HttpReject, GetURLByTypeResponse] = await Utils.to(XhrManager.sendToLoginSecure(request, "GetURLByType"));
            if (answer[0]) {
                this._logger.debug("Failed fetch url by type " + urlType + ". Status: " + answer[0].status);
            } else {
                const response: GetURLByTypeResponse = answer[1];
                this._logger.debug("Received url for type " + urlType + ". URL: " + response.URL);
                const GetParams: { [key: string]: string } = {
                    "token": response.Token,
                    "language": langCode,
                    "theme": Platform.reduxState<StoreState>().core.theme,
                    "bi": "true",
                    "platform": "new"
                };
                const targetUrl: string = ConfigUtil.UseAddressBarDomain(response.URL);
                const url: string = WebUtil.addGetParams(targetUrl, GetParams);
                Platform.dispatch(SetUrls({urls: [{type: urlType, url}]}));
            }
        }
    }
}
