import {LangCode} from "platform/enum/LangCode";
import Platform from "platform/Platform";

export class LanguageUtil {

    public static languageCode = (): LangCode => {
        const {langCode, detectedLangCode} = Platform.reduxState().translation;
        return langCode !== LangCode.NONE ? langCode : detectedLangCode;
    }
}
