import {GetUrlType} from "platform/protocol/enum/GetUrlType";

enum UrlType {

    SignUp = "signUp",
    NewLogin = "newLogin",
    GoogleSignUp = "googleSignUp",
    FacebookSignUp = "facebookSignUp",
    GoogleLogin = "googleLogin",
    FacebookLogin = "facebookLogin",
    EconomicCalendarUrl = "economicCalendar",
    CreditPromoTcUrl = "creditPromoTcUrl",
    Deposit = "deposit",
    Profit = "profit",
    ProfessionalServiceAgreement = "professionalServiceAgreement",
    Documents = "documents",
    Kyc = "kyc",
    Professional = "professional",
    DepositLimits = "depositLimits",
    ShareInfo = "shareInfo",
}

namespace UrlType {

    export function getUrlType(urlType: UrlType): GetUrlType {
        if (urlType) {
            switch (urlType) {
                case UrlType.Deposit:
                    return GetUrlType.Deposit;
                case UrlType.Profit:
                    return GetUrlType.Profit;
                case UrlType.ProfessionalServiceAgreement:
                    return GetUrlType.ProfessionalServiceAgreement;
                case UrlType.Documents:
                    return GetUrlType.Documents;
                case UrlType.Kyc:
                    return GetUrlType.KYC;
                case UrlType.Professional:
                    return GetUrlType.ProfessionalAccount;
                case UrlType.DepositLimits:
                    return GetUrlType.DepositLimits;
                case UrlType.ShareInfo:
                    return GetUrlType.ShareInfo;
            }
        }
        return null;
    }
}

export {UrlType};
