import React, {Component} from "react";

const AsyncComponent = (importComponent) => {
    return class extends Component {

        private _proceed: boolean;

        state = {
            component: null
        }

        componentDidMount() {
            this._proceed = true;
            importComponent()
                .then(cmp => {
                    if (this._proceed) {
                        this.setState({component: cmp.default});
                    }
                });
        }

        componentWillUnmount() {
            this._proceed = false;
        }

        render() {
            const C = this.state.component;
            return C ? <C {...this.props}/> : null;
        }
    };
};

export default AsyncComponent;
