/* eslint-disable no-undef */
window._cio = [];

function CustomerIOInject(orgId, siteId, callback) {
    var a,b,c;a=function(f){return function(){_cio.push([f].
    concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
        "sidentify","track","page","on","off"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])}
    var t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
    t.async = true;
    t.id = 'cio-tracker';
    t.setAttribute('data-site-id', siteId);
    t.setAttribute('data-use-array-params', 'true');
    t.setAttribute('data-in-app-org-id', orgId);
    t.setAttribute('data-use-in-app', 'true');
    t.setAttribute('data-auto-track-page', 'true');
    t.onload = callback;
    t.src = 'https://assets.customer.io/assets/track-eu.js';
    s.parentNode.insertBefore(t, s);
}

export {
    CustomerIOInject
};
