import {ActionCreatorDefinition, createAction} from "redux-ts";
import {Popup} from "platform/redux/popups/PopupsReduxState";

export const ShowPopupType: string =  "ShowPopup";
export const HidePopupType: string =  "HidePopup";
export const CloseAllPopupsType: string =  "CloseAllPopups";

export interface ShowPopupPayload {

    popup: Popup;
}

export interface HidePopupPayload {

    popup: Popup;
}

export const ShowPopup: ActionCreatorDefinition<ShowPopupPayload, any> = createAction<ShowPopupPayload>(ShowPopupType);
export const HidePopup: ActionCreatorDefinition<HidePopupPayload, any> = createAction<HidePopupPayload>(HidePopupType);
export const CloseAllPopups: ActionCreatorDefinition<any, any> = createAction<any>(CloseAllPopupsType);
