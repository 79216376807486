enum ImgStatus {

    Exist,
    NotExist
}

class Img {

    private constructor() {
    }

    public static Status = (url: string, timeout: number = 5000): Promise<ImgStatus> => {
        return new Promise(resolve => {
            const img = new Image();
            const handler = setTimeout(() => {
                img.src = "";
                resolve(ImgStatus.NotExist);
            }, timeout);
            img.onload = () => {
                clearTimeout(handler);
                resolve(ImgStatus.Exist)
            };
            img.onerror = () => {
                clearTimeout(handler);
                resolve(ImgStatus.NotExist)
            };
            img.src = url;
        });
    }
}

export {
    ImgStatus,
    Img
};
